import {
  IonList,
  IonItem,
  IonSearchbar,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonButtons,
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
} from "@ionic/react";
import "./SearchFilter.css";
import "../theme/theme.css";
const SearchFilter = () => {
  return (
    <IonList className="custom_list">
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeMd="5">
            <IonSearchbar class="custom_searchbar"></IonSearchbar>
          </IonCol>
          <IonCol size="12" sizeMd="2">
            <IonItem lines="none" className="custom_select">
              <IonLabel>All Dates</IonLabel>
              <IonSelect interface="popover">
                <IonSelectOption value="apples">Apples</IonSelectOption>
                <IonSelectOption value="oranges">Oranges</IonSelectOption>
                <IonSelectOption value="bananas">Bananas</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size="12" sizeMd="2">
            <IonItem lines="none" className="custom_select">
              <IonLabel>All Formats</IonLabel>
              <IonSelect interface="popover">
                <IonSelectOption value="apples">Apples</IonSelectOption>
                <IonSelectOption value="oranges">Oranges</IonSelectOption>
                <IonSelectOption value="bananas">Bananas</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size="12" sizeMd="2">
            <IonItem lines="none" className="custom_select">
              <IonLabel>All Locations</IonLabel>
              <IonSelect interface="popover">
                <IonSelectOption value="apples">Apples</IonSelectOption>
                <IonSelectOption value="oranges">Oranges</IonSelectOption>
                <IonSelectOption value="bananas">Bananas</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size="12" sizeMd="1">
            <IonButton class="secondary_btn second-btn">Search</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonList>
  );
};

export default SearchFilter;
