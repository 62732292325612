import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Home.css";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import Http from "../../services/Http.Service";
import { QueryBySearchs } from "../../services/EventPassportCode";
import { Contacts, EventCodeSearch } from "../../services/Api.Common";
import { Preferences } from "@capacitor/preferences";
import ComingSoon from "../PlaceHolders/ComingSoon";

const Home: React.FC = () => {
  const history = useHistory();
  const [sponsorImageURL, setSponsorImageUrl] = useState<any>([]);

  // const [email, setEmail] = useState<string>('')
  const [Code, setCode] = useState<string>("");
  const [IsOpen, setIsOpen] = useState<boolean>(false);

  // const test = async () => {
  //     const query = QueryBySearchs([
  //         { FieldName: "Email", Code: email },
  //     ]);
  //     const event = await Http.search(Contacts, query)
  //     console.log(event)

  //     let usrID = event.data.data.data.length > 0 ? event.data.data.data[0].id : ''
  //     console.log(usrID)

  //     const Res = {
  //         payload: {
  //             mailToArray: [{
  //                 EventPassportCode: 'STARTUP',
  //                 _id: usrID,
  //                 Email: email
  //             }]
  //         }
  //     }

  //     await Http.CreateUpdate("/growthevents/bulkinvite", Res)
  // }

  const onVerify = async () => {
    const querys = QueryBySearchs([{ FieldName: "Code", Code: Code }]);
    let coverImage = "";
    try {
      const event = await Http.Update(EventCodeSearch, querys);
      // event.data.data? setIsOpen(true): setIsOpen(false)
      if (event.data.error === false && event.data.data.count < 1) {
        setIsOpen(true);
      } else {
        let eventData = event.data.data.data[0];
        console.log(eventData);
        let imageFile = eventData.CoverImage;

        if (
          event.data.data.data[0].CoverImage !== "" &&
          event.data.data.data[0].CoverImage.length > 0
        ) {
          await Http.getFileURL(imageFile[0].File).then((data) => {
            coverImage = data ? data.data.data.url : "";
          });
        }
        console.log(coverImage);

        let EventData = JSON.stringify(eventData);

        Preferences.set({
          key: "event",
          value: EventData,
        });

        Preferences.set({
          key: "imageUrl",
          value: coverImage,
        });
        history.push("/event/detail");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="home-card">
      <>
        <IonCard>
          <IonCardContent>
            <IonGrid>
              <ComingSoon />
              {/*  <IonRow>
                <IonCol style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      color: "rgb(90 168 54)",
                      fontSize: "35px",
                      marginBottom: "10px",
                    }}
                  >
                    <b>Growth Events</b>
                  </h1>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{ textAlign: "center" }}>
                  <p>
                    Growth Events is an enterprise plateform and service group that is dedicated to
                    helping business grow their strategic revenue base at live events
                  </p>
                </IonCol>
              </IonRow>
              <IonRow className="Enter_code">
                <IonCol size="12" sizeMd="6" class="event_code_paragraph">
                  <p>Please Enter Your Event Code</p>
                </IonCol>
                <IonCol size="12" sizeMd="4">
                  <IonInput
                    className="event_code_input"
                    type="text"
                    placeholder="Event Code"
                    onIonChange={(e: any) => setCode(e.target.value)}
                  />
                </IonCol>
                <IonCol>
                  <IonButton class="event_code_btn" onClick={() => onVerify()}>
                    Go
                  </IonButton>
                </IonCol>
              </IonRow>
              {IsOpen && (
                <IonAlert
                  isOpen={IsOpen}
                  header="Alert"
                  subHeader="Important message"
                  message="Event Record not Found !"
                  buttons={["OK"]}
                  onDidDismiss={() => setIsOpen(false)}
                ></IonAlert>
              )} */}
              {/* <IonRow style={{ marginTop: '40px' }}>
                        <IonCol size='12' sizeXl='4'>

                        </IonCol>
                        <IonCol size='12' sizeXl='8'>
                            <IonRow>
                                <IonCol size='12' sizeXs='7'>
                                    <IonInput className='home_input' style={{ background: '#fff', color: '#000' }} type='text' placeholder='Enter Email Here' onIonChange={(e: any) => setEmail(e.target.value)} />
                                </IonCol>
                                <IonCol size='12' sizeXs='5'>
                                    <IonButton className='home_btn' onClick={() => test()}>Send Invite</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow> */}
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </>
    </div>
  );
};

export default Home;
