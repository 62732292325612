import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImg,
  IonItem,
  IonAvatar,
} from "@ionic/react";
import React from "react";
import "./ProfileCard.css";

interface CardProps {
  Name: string;
  role: string;
  imgUrl: string;
  openProfile: Function;
}

const ProfileCard: React.FC<CardProps> = (props) => {
  return (
    <IonCard className="profile-card" onClick={() => props.openProfile()}>
      <IonAvatar className="profile_ionAvatar">
        <IonImg src={props.imgUrl} alt="ProfileImage" />
      </IonAvatar>
      <IonCardHeader>
        <IonCardTitle>{props.Name}</IonCardTitle>
        <IonCardSubtitle style={{ color: "black" }}>{props.role}</IonCardSubtitle>
      </IonCardHeader>
    </IonCard>
  );
};

export default ProfileCard;
