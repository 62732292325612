import Http from "../services/Http.Service";

export async function ConfirmationEmail(Email: string, calendarHref: string, templateId: string) {
  const Res = {
    payload: {
      message: {
        to: Email,
        from: "noreply@growthevents.com",
        subject: "Confirmation for GrowthEvents",
        templateId: templateId,
        dynamicTemplateData: {
          to_mail: Email,
          calendar_href: calendarHref,
        },
      },
    },
  };
  try {
    await Http.CreateUpdate("/growthevents/email", Res);
  } catch (error) {
    console.log(error);
  }
}
