import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonButton, IonItem } from "@ionic/react";

const EventGoToLogin = () => {
  return (
    <IonPage>
      <IonContent>
        <div style={{ display: "flex", height: "100vh", alignItems: "center" }}>
          <IonGrid style={{ padding: "0" }}>
            <IonRow>
              <IonCol size="12" className="custom_col">
                <h2
                  style={{
                    fontWeight: "bolder",
                    marginBottom: "8px",
                    fontSize: "28px",
                  }}
                >
                  You already are a GrowthEvents User
                </h2>
              </IonCol>
              <IonCol size="12" className="custom_col">
                <span style={{ lineHeight: "2" }}>
                  Your email is already associated with a GrowthEvents account. <br />
                  To see the Event detail, please go to your account.
                </span>
              </IonCol>
              <IonCol>
                <IonItem lines="none" style={{ marginTop: "25px" }}>
                  <IonButton style={{ width: "14%", margin: "0 auto" }} className="secondary_btn">
                    Go to Login
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EventGoToLogin;
