import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

const PrivacyPolicy = () => {
  return (
    <>
      {/* <IonContent> */}
      <IonGrid>
        <IonRow
          style={{
            background: "#26B058",
            color: "white",
            height: "300px",
            alignItems: "center",
          }}
        >
          <IonCol>
            <IonRow>
              <IonCol>
                <h1 style={{ fontWeight: "bold" }}>growthevents.app</h1>
                <h1 style={{ fontWeight: "bold" }}>Privacy Policy</h1>
                <p>
                  In order to receive information about your Personal Data, the purposes and the
                  parties the Data is shared with, <br />
                  contact the Owner.
                </p>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5 style={{ fontWeight: "bold" }}>Owner and Data Controller</h5>
            <p style={{ marginBottom: "0px" }}>
              GrowthCloud 412 Linden Avenue Rochester, NY 14625 <br />
              Owner contact email: info@growthcloud.com
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5 style={{ fontWeight: "bold" }}>Types of Data collected</h5>
            <p style={{ marginBottom: "40px" }}>
              Complete details on each type of Personal Data collected are provided in the dedicated
              sections of this <br />
              privacy policy or by specific explanation texts displayed prior to the Data
              collection.Personal Data may be <br />
              freely provided by the User, or, in case of Usage Data, collected automatically when
              using this <br />
              Application.Unless specified otherwise, all Data requested by this Application is
              mandatory and failure to <br />
              provide this Data may make it impossible for this Application to provide its services.
              <br />
              <br />
              In cases where this Application specifically states that some Data is not mandatory,
              Users are free not <br />
              to communicate this Data without consequences to the availability or the functioning
              of the Service.Users who <br />
              are uncertain about which Personal Data is mandatory are welcome to contact the
              Owner.Any use of Cookies – <br />
              or of other tracking tools – by this Application or by the owners of third-party
              services used by this Application <br />
              serves the purpose of providing the Service required by the User, in addition to any
              other purposes described in <br />
              the present document and in the Cookie Policy, if available.
              <br />
              <br />
              Users are responsible for any third-party Personal Data obtained, published or shared
              through this Application <br />
              and confirm that they have the third party's consent to provide the Data to the Owner.
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="7">
            <IonItem lines="none" className="custom_select">
              <IonLabel>The rights of Users</IonLabel>
              <IonSelect interface="popover">
                <IonSelectOption value="apples">Apples</IonSelectOption>
                <IonSelectOption value="oranges">Oranges</IonSelectOption>
                <IonSelectOption value="bananas">Bananas</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="7">
            <IonItem lines="none" className="custom_select">
              <IonLabel>The rights of Users</IonLabel>
              <IonSelect interface="popover">
                <IonSelectOption value="apples">Apples</IonSelectOption>
                <IonSelectOption value="oranges">Oranges</IonSelectOption>
                <IonSelectOption value="bananas">Bananas</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="7">
            <IonItem lines="none" className="custom_select">
              <IonLabel>Additional information about Data collection and processing</IonLabel>
              <IonSelect interface="popover">
                <IonSelectOption value="apples">Apples</IonSelectOption>
                <IonSelectOption value="oranges">Oranges</IonSelectOption>
                <IonSelectOption value="bananas">Bananas</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="7">
            <IonItem lines="none" className="custom_select">
              <IonLabel>Definitions and legal references</IonLabel>
              <IonSelect interface="popover">
                <IonSelectOption value="apples">Apples</IonSelectOption>
                <IonSelectOption value="oranges">Oranges</IonSelectOption>
                <IonSelectOption value="bananas">Bananas</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
      {/* </IonContent> */}
    </>
  );
};

export default PrivacyPolicy;
