import { Fragment, useRef } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import {
  calendarClearOutline,
  closeOutline,
  locationOutline,
  stopwatchOutline,
} from "ionicons/icons";
import { UserInterface } from "../pages/User/EventPrincipalTemplate";
import EventFinishedModal from "../components/EventFinishedModal";

interface PrincipalTemplateInterface {
  firstLogo: string;
  secondLogo: string;
  heading: string;
  subline: string;
  sublinePlace: string;
  firstParagraph: string;
  secondParagraph: string;
  thirdParagraph: string;
  eventList: string[];
  registerText: string;
  eventDate: string;
  eventPlace: string;
  eventAddress: string;
  eventState: string;
  calendarLink: string;
  addToCalendarRef: any;
  addToCalendarButton: string;
  onValueChange: Function;
  handleChange: Function;
  updateInfo: Function;
  Name: string;
  UserData: UserInterface;
  phone: string;
  confirmationModalOpened: boolean;
  DataUpdated: boolean;
  dismiss: any;
  Registration: {
    Status: string;
    Message1: string;
    Message2: string;
  };
  bottomLeftImage: string;
  bottomRightImage: string;
  submitButtonMessage: string;
  agendaDates: string[];
  banner: string;
  submitting: boolean;
  eventFinishedModalOpened: boolean;
  setEventFinishedModalOpened: any;
}

const PrincipalTemplate = ({
  firstLogo,
  secondLogo,
  heading,
  subline,
  sublinePlace,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  eventList,
  registerText,
  eventDate,
  eventPlace,
  eventAddress,
  eventState,
  calendarLink,
  addToCalendarRef,
  addToCalendarButton,
  onValueChange,
  handleChange,
  updateInfo,
  Name,
  UserData,
  phone,
  confirmationModalOpened,
  DataUpdated,
  dismiss,
  Registration,
  bottomLeftImage,
  bottomRightImage,
  submitButtonMessage,
  agendaDates,
  banner,
  submitting,
  eventFinishedModalOpened,
  setEventFinishedModalOpened,
}: PrincipalTemplateInterface) => {
  return (
    <IonPage>
      <IonContent>
        <IonGrid class="EmailFormat">
          <IonRow>
            <IonCol sizeMd="6" size="12" style={{ padding: "0px" }}>
              <div className="banner-background">
                <IonRow>
                  <IonCol size="6">
                    <IonImg className="email-image" alt="first-logo" src={firstLogo} />
                  </IonCol>
                  <IonCol size="6" className="amz_logo">
                    <IonImg className="email-image" alt="second-logo" src={secondLogo} />
                  </IonCol>
                </IonRow>
                <IonRow style={{ marginTop: "20px" }}>
                  <IonCol size="12">
                    <h1 className="email-heading chicago_email_heading">{heading}</h1>
                    <h1 className="email-second-heading chicago_second_heading">
                      <b>
                        {subline}
                        <br /> {sublinePlace}
                      </b>
                    </h1>

                    <p style={{ textAlign: "justify" }}>{firstParagraph}</p>
                    <p style={{ textAlign: "justify" }}>{secondParagraph}</p>
                    <p style={{ textAlign: "justify" }}>{thirdParagraph}</p>
                    <ul className="event-list">
                      {eventList.map((event: string, i) => {
                        return <li key={i}>{event}</li>;
                      })}
                    </ul>
                    <p className="email-pera-heading">{registerText}</p>
                  </IonCol>
                </IonRow>

                <IonRow style={{ paddingLeft: "0px", paddingBottom: 20 }}>
                  <IonCol size="1">
                    <IonLabel>
                      <IonIcon
                        slot="start"
                        icon={calendarClearOutline}
                        class="email-icon test_email_icon"
                      ></IonIcon>
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ color: "#d1d1d1" }}>
                      Date <br />
                      <>{eventDate}</>
                    </IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow style={{ paddingLeft: "0px", paddingBottom: 20 }}>
                  <IonCol size="1">
                    <IonLabel>
                      <IonIcon
                        slot="start"
                        icon={stopwatchOutline}
                        class="email-icon test_email_icon"
                      ></IonIcon>
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ color: "#d1d1d1" }}>
                      Agenda <br />
                      {agendaDates?.map((date, i) => (
                        <Fragment key={i}>
                          {date} {i + 1 !== agendaDates.length && <br />}
                        </Fragment>
                      ))}
                    </IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow style={{ paddingLeft: "0px", paddingBottom: 20 }}>
                  <IonCol size="1">
                    <IonLabel>
                      <IonIcon
                        slot="start"
                        icon={locationOutline}
                        class="email-icon test_email_icon"
                      ></IonIcon>
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ color: "#d1d1d1" }}>
                      Join us at <br />
                      <>
                        {eventPlace}
                        <br />
                        {eventAddress}
                        <br />
                        {eventState}
                      </>
                    </IonLabel>
                  </IonCol>
                  <IonCol class="ion-justify-content-end" style={{ display: "flex" }}>
                    <div className="add_cal">
                      <a ref={addToCalendarRef} className="add-to-calendar" href={calendarLink}>
                        {addToCalendarButton}
                      </a>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
            <IonCol sizeMd="6" size="12" style={{ padding: "0px" }}>
              <img
                src={banner}
                alt="banner"
                style={{
                  height: "100%",
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow style={{ paddingTop: "40px" }}>
            <IonCol sizeMd="4" size="12">
              <div className="">
                <IonLabel class="email-label">
                  Name<p style={{ color: "red", display: "inline-block", marginLeft: "5px" }}>*</p>
                </IonLabel>
                <IonInput
                  className="email-Feild"
                  onIonChange={(e) => onValueChange(e)}
                  value={Name}
                  required={true}
                  name="Name"
                  placeholder="Name"
                  type="text"
                />
              </div>
            </IonCol>
            <IonCol sizeMd="4" size="12">
              <div className="">
                <IonLabel class="email-label">
                  Company
                  <p style={{ color: "red", display: "inline-block", marginLeft: "5px" }}>*</p>
                </IonLabel>
                <IonInput
                  className="email-Feild"
                  onIonChange={(e) => onValueChange(e)}
                  value={UserData.Company}
                  name="Company"
                  placeholder="Company"
                  type="text"
                />
              </div>
            </IonCol>
            <IonCol sizeMd="4" size="12">
              <div className="">
                <IonLabel class="email-label">
                  Email<p style={{ color: "red", display: "inline-block", marginLeft: "5px" }}>*</p>
                </IonLabel>
                <IonInput
                  className="email-Feild"
                  placeholder="Email"
                  onIonChange={(e) => onValueChange(e)}
                  value={UserData.Email}
                  name="Email"
                  type="email"
                />
              </div>
            </IonCol>
            <IonCol sizeMd="4" size="12">
              <div className="">
                <IonLabel class="email-label">
                  Title<p style={{ color: "red", display: "inline-block", marginLeft: "5px" }}>*</p>
                </IonLabel>
                <IonInput
                  className="email-Feild"
                  placeholder="Title"
                  name="JobTitle"
                  onIonChange={(e) => onValueChange(e)}
                  value={UserData.JobTitle}
                  type="text"
                />
              </div>
            </IonCol>
            <IonCol sizeMd="4" size="12">
              <div className="">
                <IonLabel class="email-label">Mobile</IonLabel>
                <IonInput
                  className="email-Feild"
                  placeholder="(XXX)-XXX-XXXX"
                  name="Mobile"
                  onIonChange={(e) => handleChange(e)}
                  value={phone}
                  type="tel"
                  maxlength={10}
                />
              </div>
            </IonCol>
            <IonCol sizeMd="6" size="12">
              <IonButton
                className="email-button"
                onClick={() => updateInfo()}
                id="open-confirmation-modal"
                expand="block"
              >
                {submitButtonMessage}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <div className="email-footer">
                <ul className="emial-list">
                  <li style={{ margin: "0px" }}>
                    <IonImg className="email-image" src={bottomLeftImage} />
                  </li>

                  <li style={{ margin: "0px" }}>
                    <IonImg className="email-image" src={bottomRightImage} />
                  </li>
                </ul>
              </div>
            </IonCol>
          </IonRow>
          <IonModal
            id="confirmation-modal"
            backdropDismiss={false}
            isOpen={confirmationModalOpened}
          >
            {!submitting ? (
              <div className="wrapper">
                <div className="btn_close_wrapper">
                  <IonButton fill="clear" onClick={dismiss} className="btn_close">
                    <IonIcon size="large" icon={closeOutline} />
                  </IonButton>
                </div>
                <h1 className="modal_header">{Registration.Status}</h1>
                <p
                  style={{
                    textAlign: "center",
                    margin: "20px",
                    lineHeight: "25px",
                  }}
                >
                  {Registration.Message1}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    margin: "30px",
                    lineHeight: "25px",
                  }}
                >
                  {Registration.Message2}
                </p>
              </div>
            ) : (
              <div
                style={{ height: "250px", width: " 500px", textAlign: "center" }}
                className="wrapper"
              >
                <div className="btn_close_wrapper">
                  <IonButton fill="clear" onClick={dismiss} disabled className="btn_close">
                    <IonIcon size="large" icon={closeOutline} />
                  </IonButton>
                </div>
                <IonSpinner class="spinnerProfile" name="crescent" />
              </div>
            )}
          </IonModal>
          <EventFinishedModal
            open={eventFinishedModalOpened}
            dismiss={() => setEventFinishedModalOpened(false)}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PrincipalTemplate;
