import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Http from "../../services/Http.Service";
import { EventCode, EventContacts } from "../../services/Api.Common";
import { QueryBySearchs } from "../../services/EventPassportCode";

import "./UserProfileReg.css";
import { Preferences } from "@capacitor/preferences";
import { v4 as uuid } from "uuid";
import PrincipalTemplate from "../../templates/PrincipalTemplate";
import EventContactRegistration from "../../helpers/EventContactRegistration";
import { IsAcceptedEmail } from "../../helpers/IsAcceptedEmail";
import { eventFinished } from "../../helpers/FinishedEvent";

interface EventInterface {
  sendedEmail: {
    emailSender: string;
    emailSubject: string;
    emailTemplateId: string;
    notAcceptedEmailTemplateId: string;
  };
  moduleAPIName: string;
  firstLogo: string;
  secondLogo: string;
  heading: string;
  subline: string;
  sublinePlace: string;
  firstParagraph: string;
  secondParagraph: string;
  thirdParagraph: string;
  eventList: string[];
  registerText: string;
  eventDate: string;
  eventPlace: string;
  eventAddress: string;
  eventState: string;
  calendarLink: string;
  addToCalendarButton: string;
  bottomLeftImage: string;
  bottomRightImage: string;
  submitButtonMessage: string;
  agendaDates: string[];
  banner: string;
  registrationMessage: string;
  openToAllDomains?: boolean;
}

export interface UserInterface {
  _id: string;
  Name: string;
  Email: string;
  Mobile: {
    id: string;
    Phone: string;
    Extension: string;
    Primary: boolean;
    Type: {
      label: string;
      value: string;
      color: string;
    };
    Description: string;
  }[];
  Company: string;
  JobTitle: string;
  EventPassportCode: string;
}

const EventPrincipalTemplate = ({
  sendedEmail,
  moduleAPIName,
  firstLogo,
  secondLogo,
  heading,
  subline,
  sublinePlace,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  eventList,
  registerText,
  eventDate,
  eventPlace,
  eventAddress,
  eventState,
  calendarLink,
  addToCalendarButton,
  bottomLeftImage,
  bottomRightImage,
  submitButtonMessage,
  agendaDates,
  banner,
  registrationMessage,
  openToAllDomains = false,
}: EventInterface) => {
  const history = useHistory();
  const { emailSender, emailSubject, emailTemplateId, notAcceptedEmailTemplateId } = sendedEmail;
  const { Code, ID, AddToCalendar }: { Code: string; ID: string; AddToCalendar: string } =
    useParams();
  const [EventData, setEventData] = useState<any>({});
  const [UserData, setUserData] = useState<any>({});
  const [eventFinishedModalOpened, setEventFinishedModalOpened] = useState<any>(false);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState<any>(false);
  const [Name, setName] = useState("");
  const [DataUpdated, setDataUpdated] = useState(false);
  const [phone, setPhone] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [Registration, setRegistration] = useState({
    Status: "",
    Message1: "",
    Message2: "",
  });
  const addToCalendarRef = useRef<any>();
  const querys = QueryBySearchs([{ FieldName: "Code", Code: Code }]);
  const isAcceptedEmail = openToAllDomains ? true : IsAcceptedEmail(UserData.Email);

  useEffect(() => {
    if (!AddToCalendar || !addToCalendarRef.current) return;

    addToCalendarRef?.current?.click?.();

    history.replace({
      pathname: `/event/${Code}${"&" + new URLSearchParams({ AttendeeID: ID }).toString()}`,
    });
  }, [AddToCalendar, Code, ID, history]);

  useEffect(() => {
    getData();
  }, []);

  // Rendering template component
  return (
    <PrincipalTemplate
      submitting={submitting}
      firstLogo={firstLogo}
      secondLogo={secondLogo}
      heading={heading}
      subline={subline}
      sublinePlace={sublinePlace}
      firstParagraph={firstParagraph}
      secondParagraph={secondParagraph}
      thirdParagraph={thirdParagraph}
      eventList={eventList}
      registerText={registerText}
      eventDate={eventDate}
      eventPlace={eventPlace}
      eventAddress={eventAddress}
      eventState={eventState}
      calendarLink={calendarLink}
      addToCalendarRef={addToCalendarRef}
      addToCalendarButton={addToCalendarButton}
      onValueChange={onValueChange}
      handleChange={handleChange}
      updateInfo={updateInfo}
      Name={Name}
      UserData={UserData}
      phone={phone}
      confirmationModalOpened={confirmationModalOpened}
      DataUpdated={DataUpdated}
      dismiss={dismiss}
      Registration={Registration}
      bottomLeftImage={bottomLeftImage}
      bottomRightImage={bottomRightImage}
      submitButtonMessage={submitButtonMessage}
      agendaDates={agendaDates}
      banner={banner}
      eventFinishedModalOpened={eventFinishedModalOpened}
      setEventFinishedModalOpened={setEventFinishedModalOpened}
    />
  );

  // Functions used in this component

  function onValueChange(e: any) {
    if (e.target.name === "Name") {
      setName(e.target.value);
    } else {
      setUserData({ ...UserData, [e.target.name]: e.target.value });
    }
  }

  function dismiss() {
    setConfirmationModalOpened(false);

    if (Registration.Status === "Registration Completed") {
      setUserData({});
      setPhone("");
      setName("");
    } /* else if (Registration.Status === "Registration Failed") {
      window.location.reload();
    } */
  }

  async function updateInfo() {
    try {
      if (eventFinished(EventData.Ends)) {
        return setEventFinishedModalOpened(true);
      }
      let UserName = Name?.trim();
      setConfirmationModalOpened(true);

      let Temp = UserData;
      Temp.Name = UserName;

      if (!Temp.Email || !Temp.Company || !Temp.JobTitle || !Temp.Name) {
        setRegistration({
          Status: "Registration Failed",
          Message1: "Ensure you have all required details filled out!",
          Message2: "",
        });
        setDataUpdated(true);
        setSubmitting(false);
        return;
      }

      const regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/;
      const valid = regex.test(Temp.Email);

      if (!valid) {
        setRegistration({
          Status: "Registration Failed",
          Message1: "Please provide a valid email address.",
          Message2: "",
        });
        setDataUpdated(true);
        setSubmitting(false);
        return;
      }

      if (Temp.Email !== undefined) {
        setSubmitting(true);
        const res = await EventContactRegistration(
          UserData,
          EventData,
          Code,
          ID,
          emailTemplateId,
          notAcceptedEmailTemplateId,
          openToAllDomains
        );
        if (res != null) {
          if (res.error === false) {
            if (!isAcceptedEmail) {
              setRegistration({
                Status: "Registration Completed",
                Message1: `Thank you for your submission. Your registration is pending availability.`,
                Message2: "We will get back to you shortly!",
              });
            } else {
              setRegistration({
                Status: "Registration Completed",
                Message1: registrationMessage,
                Message2: "Check your email to see the event confirmation.",
              });
            }
            setSubmitting(false);
          }
        }
      } else {
        setRegistration({
          Status: "Registration Failed",
          Message1: "Ensure you have all required details filled out!",
          Message2: "",
        });
        setSubmitting(false);
        setDataUpdated(true);
      }
    } catch (error) {
      setRegistration({
        Status: "Registration Failed",
        Message1: "Ensure you have all required details filled out!",
        Message2: "",
      });
      setSubmitting(false);
      setDataUpdated(true);
    }
  }

  function handleChange(e: any) {
    // Remove all non-numeric characters from the input
    const rawInput = e.target.value.replace(/\D/g, "");

    // Format the phone number as (xxx)-xxx-xxxx
    const formattedInput = rawInput.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1)-$2-$3");
    setPhone(formattedInput);

    const formattedObject = [
      {
        id: uuid(),
        Phone: `+1${rawInput}`,
        Extension: "",
        Primary: true,
        Type: {
          label: "Personal",
          value: "Personal",
          color: "#FFFFFF",
        },
        Description: "",
      },
    ];

    setUserData({ ...UserData, [e.target.name]: formattedObject });
  }

  async function getData() {
    const Event = await Http.search(EventCode, querys);
    setEventData(Event.data.data.data[0]);

    if (ID != null) {
      try {
        const User = await Http.getById(EventContacts, ID);
        setUserData({
          Name: User.data.data.data.AttendeeName,
          // LastName: User.data.data.data.LastName,
          Email: User.data.data.data.AttendeeEmail,
          Mobile: User.data.data.data.AttendeeMobile,
          Company: User.data.data.data.AttendeeCompany,
          JobTitle: User.data.data.data.AttendeeTitle,
        });
        setName(User.data.data.data.AttendeeName);
      } catch {
        setUserData({});
        setName("");
      }
    }

    await Preferences.set({
      key: "event",
      value: JSON.stringify(Event.data.data.data[0]),
    });
  }
};

export default EventPrincipalTemplate;
