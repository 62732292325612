import {
  calendarClearOutline,
  closeOutline,
  locationOutline,
  stopwatchOutline,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Http from "../../services/Http.Service";
import { Contacts, EventCode, EventContacts } from "../../services/Api.Common";
import { QueryBySearchs } from "../../services/EventPassportCode";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import "./UserProfileReg.css";
import { Preferences } from "@capacitor/preferences";
import { v4 as uuid } from "uuid";
import EventContactRegistration from "../../helpers/EventContactRegistration";
import { IsAcceptedEmail } from "../../helpers/IsAcceptedEmail";
import { eventFinished } from "../../helpers/FinishedEvent";
import EventFinishedModal from "../../components/EventFinishedModal";

const UserProfileReg = () => {
  const history = useHistory();
  const { Code, ID, AddToCalendar }: { Code: string; ID: string; AddToCalendar: string } =
    useParams();
  const [EventData, setEventData] = useState<any>({});
  const [UserData, setUserData] = useState<any>({});
  const [eventFinishedModalOpened, setEventFinishedModalOpened] = useState<any>(false);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState<any>(false);
  const [Name, setName] = useState("");
  const [DataUpdated, setDataUpdated] = useState(false);
  const [phone, setPhone] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [Registration, setRegistration] = useState({
    Status: "",
    Message1: "",
    Message2: "",
  });
  const addToCalendarRef = useRef<any>();
  const querys = QueryBySearchs([{ FieldName: "Code", Code: Code }]);
  const noUser = !Object.keys(UserData).length;
  const isAcceptedEmail = IsAcceptedEmail(UserData.Email);
  const templateId = "d-acb5b70980e346e5b17450ced13fd43e";
  const notAcceptedEmailTemplateId = "";

  // Used a redirect link from Confirmation Email Template
  // Downloads ICS file and removes "AddToCalendar" query parameter.
  useEffect(() => {
    if (!AddToCalendar || !addToCalendarRef.current) return;

    addToCalendarRef?.current?.click?.();

    history.replace({
      pathname: `/event/${Code}${"&" + new URLSearchParams({ AttendeeID: ID }).toString()}`,
    });
  }, [AddToCalendar, Code, ID, history]);

  const getData = async () => {
    const Event = await Http.search(EventCode, querys);
    setEventData(Event.data.data.data[0]);

    if (ID != null) {
      try {
        const User = await Http.getById(EventContacts, ID);
        setUserData({
          Name: User.data.data.data.AttendeeName,
          // LastName: User.data.data.data.LastName,
          Email: User.data.data.data.AttendeeEmail,
          Mobile: User.data.data.data.AttendeeMobile,
          Company: User.data.data.data.AttendeeCompany,
          JobTitle: User.data.data.data.AttendeeTitle,
        });
        setName(User.data.data.data.AttendeeName);
      } catch {
        setUserData({});
        setName("");
      }
    }

    await Preferences.set({
      key: "event",
      value: JSON.stringify(Event.data.data.data[0]),
    });
  };

  const onValueChange = (e: any) => {
    if (e.target.name === "Name") {
      setName(e.target.value);
    } else {
      setUserData({ ...UserData, [e.target.name]: e.target.value });
    }
  };

  const dismiss = () => {
    setConfirmationModalOpened(false);

    if (Registration.Status === "Registration Completed") {
      setUserData({});
      setPhone("");
      setName("");
    }
    /* else if (Registration.Status === "Registration Failed") {
      window.location.reload();
    } */
  };

  const updateInfo = async () => {
    try {
      if (eventFinished(EventData.Ends)) {
        return setEventFinishedModalOpened(true);
      }

      let UserName = Name?.trim();
      setConfirmationModalOpened(true);

      let Temp = UserData;
      Temp.Name = UserName;

      if (!Temp.Email || !Temp.Company || !Temp.JobTitle || !Temp.Name) {
        setRegistration({
          Status: "Registration Failed",
          Message1: "Ensure you have all required details filled out!",
          Message2: "",
        });
        setDataUpdated(true);
        setSubmitting(false);
        return;
      }

      const regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/;
      const valid = regex.test(Temp.Email);

      if (!valid) {
        setRegistration({
          Status: "Registration Failed",
          Message1: "Please provide a valid email address.",
          Message2: "",
        });
        setDataUpdated(true);
        setSubmitting(false);
        return;
      }

      if (Temp.Email !== undefined) {
        setSubmitting(true);
        const res = await EventContactRegistration(
          UserData,
          EventData,
          Code,
          ID,
          templateId,
          notAcceptedEmailTemplateId
        );
        if (res != null) {
          if (res.error === false) {
            if (!isAcceptedEmail) {
              setRegistration({
                Status: "Registration Completed",
                Message1: `Thank you for your submission. Your registration is pending availability.`,
                Message2: "We will get back to you shortly!",
              });
            } else {
              setRegistration({
                Status: "Registration Completed",
                Message1: `Thanks for Registering for the Cisco/AWS TopGolf event.`,
                Message2: "Check your email to see the event confirmation.",
              });
            }
            setSubmitting(false);
          }
        }
      } else {
        setRegistration({
          Status: "Registration Failed",
          Message1: "Ensure you have all required details filled out!",
          Message2: "",
        });
        setSubmitting(false);
        setDataUpdated(true);
      }
    } catch (error) {
      setRegistration({
        Status: "Registration Failed",
        Message1: "Ensure you have all required details filled out!",
        Message2: "",
      });
      setSubmitting(false);
      setDataUpdated(true);
    }
  };

  const handleChange = (e: any) => {
    // Remove all non-numeric characters from the input
    const rawInput = e.target.value.replace(/\D/g, "");

    // Format the phone number as (xxx)-xxx-xxxx
    const formattedInput = rawInput.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1)-$2-$3");
    setPhone(formattedInput);

    const formattedObject = [
      {
        id: uuid(),
        Phone: `+1${rawInput}`,
        Extension: "",
        Primary: true,
        Type: {
          label: "Personal",
          value: "Personal",
          color: "#FFFFFF",
        },
        Description: "",
      },
    ];

    setUserData({ ...UserData, [e.target.name]: formattedObject });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid class="EmailFormat">
          <IonRow>
            <IonCol sizeMd="6" size="12" style={{ padding: "0px" }}>
              <div className="banner-background">
                <IonRow>
                  <IonCol size="6">
                    <IonImg
                      className="email-image"
                      alt="CiscoLogo"
                      src="/assets/CiscoWhiteLogo.png"
                    />
                  </IonCol>
                  <IonCol size="6" className="amz_logo">
                    <IonImg className="email-image" alt="AwsLogo" src="/assets/aws_logo.png" />
                  </IonCol>
                </IonRow>
                <IonRow style={{ marginTop: "20px" }}>
                  <IonCol size="12">
                    <h1 className="email-second-heading">
                      <b>
                        Tee up your sales with
                        <br /> Cisco & AWS
                      </b>
                    </h1>
                    <h1 className="email-heading">
                      Join us for this exclusive networking event in <br /> San Jose at Top Golf!
                    </h1>

                    <p style={{ textAlign: "justify" }}>
                      Cisco and AWS provide a broad portfolio of joint offerings that help your
                      customers migrate to the cloud, modernize and operate in a hybrid world. With
                      26 Cisco solutions as well as EA’s now being offered through the AWS
                      Marketplace, there is more opportunity than ever for Cisco and AWS to partner
                      together.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      Come learn about the full breadth and depth of what's available, in addition
                      to some great Cisco year-end customer incentives to help you bring those deals
                      across the line!
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      Network with your AWS and Cisco counterparts to effectively sell AWS and Cisco
                      together.
                    </p>
                    <ul className="event-list">
                      <li>
                        Get to know your Cisco and AWS sales colleagues in this interactive
                        networking event.
                      </li>
                      <li>Build relationships with your account team counterparts.</li>
                      <li>
                        Build a plan for a successful 2023 with joint offerings available NOW.
                      </li>
                    </ul>

                    <p className="email-pera-heading">
                      Register below to join us for a joint networking event at TopGolf!
                    </p>
                  </IonCol>
                </IonRow>
                <IonRow style={{ paddingLeft: "0px", paddingBottom: 20 }}>
                  <IonCol size="10" sizeMd="12">
                    <IonLabel>
                      <IonIcon
                        slot="start"
                        icon={calendarClearOutline}
                        class="email-icon"
                      ></IonIcon>
                      &nbsp; May 4, 2023
                    </IonLabel>
                  </IonCol>
                  <IonCol size="10" sizeMd="12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <IonLabel>
                        <IonIcon slot="start" icon={stopwatchOutline} class="email-icon"></IonIcon>
                        &nbsp; 2:00pm PT
                      </IonLabel>
                      <a
                        ref={addToCalendarRef}
                        className="add-to-calendar"
                        href="https://growtheventstorage.blob.core.windows.net/growtheventcontainer/join-cisco-and-aws-at-this-exclusive-event-at-san-jose-top-golf-thursday-may-4th.ics"
                      >
                        Add To Calendar
                      </a>
                    </div>
                  </IonCol>
                  <IonCol size="10" sizeMd="12">
                    <IonLabel>
                      <IonIcon slot="start" icon={locationOutline} class="email-icon"></IonIcon>
                      &nbsp; 10 Topgolf Drive, San Jose, California 95002
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
            <IonCol sizeMd="6" size="12" style={{ padding: "0px" }}>
              <div className="banner-secondImage"></div>
            </IonCol>
          </IonRow>

          {/* <IonRow>
            <IonCol>
              <h1>
                <b>You are registred!</b>
              </h1>
              <p>Edit your information or invite a Co-Worker</p>
            </IonCol>
          </IonRow> */}

          <IonRow style={{ paddingTop: "40px" }}>
            <IonCol sizeMd="4" size="12">
              <div className="">
                <IonLabel class="email-label">
                  Name<p style={{ color: "red", display: "inline-block", marginLeft: "5px" }}>*</p>
                </IonLabel>
                <IonInput
                  className="email-Feild"
                  onIonChange={(e) => onValueChange(e)}
                  value={Name}
                  required={true}
                  name="Name"
                  placeholder="Name"
                  type="text"
                />
              </div>
            </IonCol>
            <IonCol sizeMd="4" size="12">
              <div className="">
                <IonLabel class="email-label">
                  Company
                  <p style={{ color: "red", display: "inline-block", marginLeft: "5px" }}>*</p>
                </IonLabel>
                <IonInput
                  className="email-Feild"
                  onIonChange={(e) => onValueChange(e)}
                  value={UserData.Company}
                  name="Company"
                  placeholder="Company"
                  type="text"
                />
              </div>
            </IonCol>
            <IonCol sizeMd="4" size="12">
              <div className="">
                <IonLabel class="email-label">
                  Email<p style={{ color: "red", display: "inline-block", marginLeft: "5px" }}>*</p>
                </IonLabel>
                <IonInput
                  className="email-Feild"
                  placeholder="Email"
                  onIonChange={(e) => onValueChange(e)}
                  value={UserData.Email}
                  name="Email"
                  type="email"
                />
              </div>
            </IonCol>
            <IonCol sizeMd="4" size="12">
              <div className="">
                <IonLabel class="email-label">
                  Title<p style={{ color: "red", display: "inline-block", marginLeft: "5px" }}>*</p>
                </IonLabel>
                <IonInput
                  className="email-Feild"
                  placeholder="Title"
                  name="JobTitle"
                  onIonChange={(e) => onValueChange(e)}
                  value={UserData.JobTitle}
                  type="text"
                />
              </div>
            </IonCol>
            <IonCol sizeMd="4" size="12">
              <div className="">
                <IonLabel class="email-label">Mobile</IonLabel>
                <IonInput
                  className="email-Feild"
                  placeholder="(XXX)-XXX-XXXX"
                  name="Mobile"
                  onIonChange={handleChange}
                  value={phone}
                  type="tel"
                  maxlength={10}
                />
              </div>
            </IonCol>
            {/* <IonCol sizeMd='4' size="12">
                            <div className=''>
                                <IonLabel class='email-label'>Shirt Size</IonLabel>
                                <IonSelect interface="popover" class='email_custom_select email-Feild' placeholder="Select Size"
                                    value={UserData.ShirtSize ? UserData.ShirtSize : selectedValue} onIonChange={handleSelectChange}>
                                    <IonSelectOption value="Small">Small</IonSelectOption>
                                    <IonSelectOption value="Medium">Medium</IonSelectOption>
                                    <IonSelectOption value="Large">Large</IonSelectOption>
                                </IonSelect>
                            </div>
                        </IonCol> */}

            {/* <IonCol sizeMd='2' size="12">
                            <div className=''>
                                <h4><b>Invite A Co-Worker <IonIcon icon={chevronForward} style={{ verticalAlign: 'middle' }}></IonIcon></b> </h4>
                            </div>
                        </IonCol>
                        <IonCol sizeMd='8' size="12">
                            <div className=''>
                                <p style={{textAlign: 'justify'}}>Please cilck here to invite others in your organisation that would benefit from the AWS & Cisco alliance. They will receive an invitation and they also must register to attend.</p>
                            </div>
                        </IonCol>
                        <IonCol sizeMd='6' size="12">
                            <div className=''>
                                <IonLabel class='email-label'>Name</IonLabel>
                                <IonInput className='email-Feild' placeholder="Jane" type='text' />
                            </div>
                        </IonCol>
                        <IonCol sizeMd='6' size="12">
                            <div className=''>
                                <IonLabel class='email-label'>Email</IonLabel>
                                <IonInput className='email-Feild' placeholder="Jane" type='text' />
                            </div>
                        </IonCol> */}
            <IonCol sizeMd="6" size="12">
              <IonButton
                className="email-button"
                onClick={() => updateInfo()}
                id="open-confirmation-modal"
                expand="block"
              >
                Submit
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <div className="email-footer">
                <ul className="emial-list">
                  <li style={{ margin: "0px" }}>
                    <IonImg className="email-image" src="/assets/CiscoWhiteLogoFooter.png" />
                  </li>

                  <li style={{ margin: "0px" }}>
                    <IonImg className="email-image" src="/assets/aws_logo_footer.png" />
                  </li>
                </ul>
              </div>
            </IonCol>
          </IonRow>
          <IonModal
            id="confirmation-modal"
            backdropDismiss={false}
            isOpen={confirmationModalOpened}
          >
            {!submitting ? (
              <div className="wrapper">
                <div className="btn_close_wrapper">
                  <IonButton fill="clear" onClick={dismiss} className="btn_close">
                    <IonIcon size="large" icon={closeOutline} />
                  </IonButton>
                </div>
                <h1 className="modal_header">{Registration.Status}</h1>
                <p
                  style={{
                    textAlign: "center",
                    margin: "20px",
                    lineHeight: "25px",
                  }}
                >
                  {Registration.Message1}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    margin: "30px",
                    lineHeight: "25px",
                  }}
                >
                  {Registration.Message2}
                </p>
              </div>
            ) : (
              <div
                style={{ height: "250px", width: " 500px", textAlign: "center" }}
                className="wrapper"
              >
                <div className="btn_close_wrapper">
                  <IonButton fill="clear" onClick={dismiss} disabled className="btn_close">
                    <IonIcon size="large" icon={closeOutline} />
                  </IonButton>
                </div>
                <IonSpinner class="spinnerProfile" name="crescent" />
              </div>
            )}
          </IonModal>
          <EventFinishedModal
            open={eventFinishedModalOpened}
            dismiss={() => setEventFinishedModalOpened(false)}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default UserProfileReg;
