import { Route, useParams } from "react-router-dom";
import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Home from "./pages/Home/Home";
import LoginPage from "./pages/Login/LoginPage";
import Registration from "./pages/Registration/Registration";
import Events from "./pages/Event/Events";
import SearchFilter from "./components/SearchFilter";
import Terms from "./pages/TermsAndPolicy/Terms";
import PrivacyPolicy from "./pages/TermsAndPolicy/PrivacyPolicy";
import EventDetailsLogin from "./pages/Event/EventDetailsLogin";
import EventGoToLogin from "./pages/Event/EventGoToLogin";
import RegistrationConfirmed from "./pages/Registration/RegistrationConfirmed";
import AttendeeInfo from "./pages/Event/AttendeeInfo";
import ProfileQR from "./pages/User/ProfileQR";
import EditProfile from "./pages/User/EditProfile";
import PrivacySetting from "./pages/User/PrivacySetting";
import EventInfo from "./pages/Event/EventInfo";
import ScheduleDetail from "./pages/Event/ScheduleDetail";
import JoinUs from "./pages/InvitationEventFlow/JoinUs";
import Details from "./pages/InvitationEventFlow/Details";
import Confirmed from "./pages/InvitationEventFlow/Confirmed";
import UserProfileReg from "./pages/User/UserProfileReg";
import EventAttendees from "./pages/Event/EventAttendees";
import ChicagoEvent from "./pages/User/ChicagoEvent";
import AustinEvent from "./pages/User/AustinEvent";
import EventPrincipalTemplate from "./pages/User/EventPrincipalTemplate";
import ReactGA from "react-ga4";
import AnaheimEvent from "./pages/Anaheim/AnaheimEvent";
import ImpactEvent from "./pages/Impact/ImpactEvent";
import ImpactEventRegistered from "./pages/Impact/ImpactEventRegistered";

if (process.env.REACT_APP_DEVELOPMENT === "dev") {
  console.log("Development");
} else {
  console.log("Production");
  console.log("6-6-23, 2:34pm EST");
  ReactGA.initialize("G-92M5Q7F1H7");
}

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        {/* Dynamic routes for different pages based on event codes */}
        {/* Don't change this to "Switch" */}
        <switch>
          <Route exact path="/event/:Code" component={Page} />
          <Route exact path="/event/:Code&AttendeeID=:ID" component={Page} />
          <Route
            exact
            path="/event/:Code&AttendeeID=:ID&AddToCalendar=:AddToCalendar"
            component={Page}
          />
          <Route exact path="/event/:Code&AttendeeID=&AddToCalendar=" component={Page} />
          <Route exact path="/event/20230827IMPCT/registered" component={ImpactEventRegistered} />
          <Route exact path="/event/:Code/detail" component={Page} />
        </switch>

        {/* <Route exact path="/event/:Code" component={UserProfileReg} />
        <Route exact path="/event/:Code&AttendeeID=:ID" component={UserProfileReg} />
        <Route
          exact
          path="/event/:Code&AttendeeID=:ID&AddToCalendar=:AddToCalendar"
          component={UserProfileReg}>
        </Route>
        <Route exact path="/event/:Code&AttendeeID=&AddToCalendar=" component={UserProfileReg}>
        </Route> */}
        {/* <Route exact path="/eventpage/:Code">
          <EventLandingPage />
        </Route> */}
        <Route exact path="/registration">
          <Registration />
        </Route>
        <Route exact path="/registration/confirmed">
          <RegistrationConfirmed />
        </Route>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/eventlogin">
          <EventDetailsLogin />
        </Route>
        {/* <Route exact path="/event/detail">
          <EventDetail />
        </Route> */}
        <Route exact path="/eventgotologin">
          <EventGoToLogin />
        </Route>
        <Route exact path="/event/detail">
          <AttendeeInfo />
        </Route>
        <Route exact path="/search">
          <SearchFilter />
        </Route>
        {/* <Route exact path="/header">
          <Header />
        </Route> */}
        {/* <Route exact path="/sidebar">
          <Sidebar />
        </Route> */}
        <Route exact path="/eventAttendee">
          <EventAttendees />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/terms">
          <Terms />
        </Route>
        <Route exact path="/privacy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/events">
          <Events />
        </Route>
        <Route exact path="/qr">
          <ProfileQR />
        </Route>
        <Route exact path="/edit/profile">
          <EditProfile />
        </Route>
        <Route exact path="/privacy/setting">
          <PrivacySetting />
        </Route>
        <Route exact path="/event/info">
          <EventInfo />
        </Route>
        <Route exact path="/schedule/details">
          <ScheduleDetail />
        </Route>
        <Route exact path="/joinus/:Code">
          <JoinUs />
        </Route>
        <Route exact path="/details">
          <Details />
        </Route>
        <Route exact path="/confirmed">
          <Confirmed />
        </Route>
      </IonReactRouter>
      {/* {window.location.pathname != '/eventpage/:Code'
        && window.location.pathname != '/registration/confirmed'
        && window.location.pathname != '/attendeeInfo'
        && window.location.pathname != '/login'
        && window.location.pathname != '/eventlogin'
        && window.location.pathname != '/eventgotologin'
        && window.location.pathname != '/registration'
        && window.location.pathname != '/search'
        && window.location.pathname != '/qr'
        && window.location.pathname != '/event/info'
        && window.location.pathname != '/schedule/details'
        && window.location.pathname != '/joinus/:Code'
        && window.location.pathname != '/details'
        && window.location.pathname != '/confirmed'
        ?
        <>
          <IonContent>
            <IonGrid style={{ padding: '0px' }}>
              <IonRow>
                <IonCol style={{ padding: '0px' }}>
                  <Header />
                </IonCol>
              </IonRow>
              {window.location.pathname != '/'
                && window.location.pathname != '/eventpage/:Code'
                && window.location.pathname != '/registration/confirmed'
                && window.location.pathname != '/attendeeInfo'
                && window.location.pathname != '/login'
                && window.location.pathname != '/eventlogin'
                && window.location.pathname != '/eventgotologin'
                && window.location.pathname != '/registration'
                && window.location.pathname != '/search'
                && window.location.pathname != '/qr'
                && window.location.pathname != '/event/info'
                && window.location.pathname != '/schedule/details'
                && window.location.pathname != '/joinus/:Code'
                && window.location.pathname != '/details'
                && window.location.pathname != '/confirmed'
                && window.location.pathname != '/terms'
                && window.location.pathname != '/privacy'
                ?

                <IonRow style={{ height: '90vh' }}>
                  <IonCol>
                    <IonRow style={{ height: '100%' }}>
                      <IonCol size='2' className='custtom_sidebar'>
                        <Sidebar />
                      </IonCol>
                      <IonCol size='12' sizeMd='10' >
                        <IonReactRouter>
                          <Route path="/Events">
                            <Events />
                          </Route>
                          <Route exact path="/edit/profile">
                            <EditProfile />
                          </Route>
                          <Route exact path="/privacy/setting">
                            <PrivacySetting />
                          </Route>
                        </IonReactRouter>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
                : <></>}
            </IonGrid>
          </IonContent>
        </>
        :
        <></>} */}
    </IonApp>
  );
};

function Page() {
  const { Code } = useParams<{ Code: string }>();

  switch (Code) {
    case "STARTUP":
      ReactGA.send({ hitType: "pageview", page: "/event/STARTUP", title: "San Jose at Top Golf!" });
      return <UserProfileReg />;
    case "20230613ANAREI":
      ReactGA.send({
        hitType: "pageview",
        page: "/event/20230613ANAREI",
        title: "Anaheim Event",
      });
      return <AnaheimEvent />;
    case "20230827IMPCT":
      ReactGA.send({
        hitType: "pageview",
        page: "/event/20230827IMPCT",
        title: "Impact Event",
      });
      return <ImpactEvent />;
    case "20230518APOAUS":
      ReactGA.send({
        hitType: "pageview",
        page: "/event/20230518APOAUS",
        title: "Austin Event",
      });
      return <AustinEvent />;
    case "20230511APOCHI":
      ReactGA.send({
        hitType: "pageview",
        page: "/event/20230511APOCHI",
        title: "Chicago Event",
      });
      return <ChicagoEvent />;
    case "20230518SEASPIN":
      ReactGA.send({
        hitType: "pageview",
        page: "/event/20230518SEASPIN",
        title: "Seattle at SPIN!",
      });
      return (
        <EventPrincipalTemplate
          sendedEmail={{
            emailSender: "noreply@growthevents.com",
            emailSubject: "Confirmation for GrowthEvents",
            emailTemplateId: "d-a18603f0d6da47359626512a28fce506",
            notAcceptedEmailTemplateId: "d-b44a305a036441209d8e0bc9e2943788",
          }}
          moduleAPIName="EventContacts"
          firstLogo="/assets/CiscoWhiteLogo.png"
          secondLogo="/assets/aws_logo.png"
          heading="Spin Up Your Sales with AWS and Cisco!"
          subline="Join us for this exclusive event in"
          sublinePlace="Seattle at SPIN!"
          firstParagraph="Cisco and AWS provide a broad portfolio of joint offerings that help your customers migrate to the cloud, modernize and operate in a hybrid world. With 26 Cisco solutions as well as EA’s now being offered through the AWS Marketplace, there is more opportunity than ever for Cisco and AWS to partner together. "
          secondParagraph="Come learn about the full breadth and depth of what's available, in addition to some great Cisco year-end customer incentives to help you bring those deals across the line!"
          thirdParagraph="Meet with your Cisco and AWS counterparts to effectively sell Cisco and AWS together."
          eventList={[
            "Get to know your Cisco and AWS sales colleagues.",
            "Build relationships with your account team counterparts.",
            "Build a plan for a successful 2023 with joint offerings available NOW.",
          ]}
          registerText="Register below to join us for a joint networking event at SPIN!"
          eventDate="May 18, 2023"
          eventPlace="Spin Seattle"
          eventAddress="1511 6th Ave"
          eventState="Seattle, WA 98101"
          calendarLink="https://growtheventstorage.blob.core.windows.net/growtheventcontainer/20230518APOSEA.ics"
          addToCalendarButton="Add To Calendar"
          bottomLeftImage="/assets/CiscoWhiteLogoFooter.png"
          bottomRightImage="/assets/aws_logo_footer.png"
          submitButtonMessage="Submit"
          agendaDates={[
            "2:00 pm - 2:15 pm Registration and Networking",
            "2:15 pm - 3:00 pm Overview from Cisco & AWS Sales Leaders",
            "3:00 pm - 5:00 pm Networking and Ping Pong",
          ]}
          banner="/assets/SPIN.jpg"
          registrationMessage="Thanks for Registering for the Cisco/AWS SPIN event."
          openToAllDomains={true}
        />
      );
    case "20230525APOIRV":
      ReactGA.send({
        hitType: "pageview",
        page: "/event/20230525APOIRV",
        title: "Irvine at Hive and Honey",
      });
      return (
        <EventPrincipalTemplate
          sendedEmail={{
            emailSender: "noreply@growthevents.com",
            emailSubject: "Confirmation for GrowthEvents",
            emailTemplateId: "d-787a8793909a4552be01815c24408832",
            notAcceptedEmailTemplateId: "d-5fc11e6a415e4a548f4c7ba0304c5660",
          }}
          moduleAPIName="EventContacts"
          firstLogo="/assets/CiscoWhiteLogo.png"
          secondLogo="/assets/aws_logo.png"
          heading="Accelerate Your Sales with Cisco & AWS!"
          subline="Join us for this exclusive event in"
          sublinePlace="Irvine at Hive and Honey!"
          firstParagraph="Cisco and AWS provide a broad portfolio of joint offerings that help your 
          customers migrate to the cloud, modernize and operate in a hybrid world. 
          With 26 Cisco solutions as well as EA’s now being offered through the AWS Marketplace, 
          there is more opportunity than ever for Cisco and AWS to partner together."
          secondParagraph="Come learn about the full breadth and depth of what's available, 
          in addition to some great Cisco year- end customer incentives to help you bring those deals across the line!"
          thirdParagraph="Meet with your Cisco and AWS counterparts to effectively sell Cisco and AWS together."
          eventList={[
            "Get to know your Cisco and AWS sales colleagues.",
            "Build relationships with your account team counterparts.",
            "Build a plan for a successful 2023 with joint offerings available NOW.",
          ]}
          registerText="Register below to join us for a joint networking event at Hive and Honey!"
          eventDate="May 25, 2023"
          eventPlace="Hive and Honey Rooftop Bar"
          eventAddress="7905 Irvine Center Drive"
          eventState="Irvine, CA 92618"
          calendarLink="https://growtheventstorage.blob.core.windows.net/growtheventcontainer/20230525APOIRV.ics"
          addToCalendarButton="Add To Calendar"
          bottomLeftImage="/assets/CiscoWhiteLogoFooter.png"
          bottomRightImage="/assets/aws_logo_footer.png"
          submitButtonMessage="Submit"
          agendaDates={[
            "3:00 pm - 3:15 pm Registration and Networking",
            "3:15 pm - 4:00 pm Overview from AWS & Cisco Sales Leaders",
            "4:00 pm - 6:00 pm Networking and Happy Hour",
          ]}
          banner="/assets/hivehoneyrooftopbar.jpg"
          registrationMessage="Thanks for Registering for the Cisco/AWS Hive and Honey event."
          openToAllDomains={true}
        />
      );
    case "TESTCODE":
      ReactGA.send({
        hitType: "pageview",
        page: "/event/TESTCODE",
        title: "San Jose at Top Golf TESTCODE",
      });
      return (
        <EventPrincipalTemplate
          sendedEmail={{
            emailSender: "noreply@growthevents.com",
            emailSubject: "Confirmation for GrowthEvents",
            emailTemplateId: "d-acb5b70980e346e5b17450ced13fd43e",
            notAcceptedEmailTemplateId: "d-5fc11e6a415e4a548f4c7ba0304c5660",
          }}
          moduleAPIName="EventContacts"
          firstLogo="/assets/CiscoWhiteLogo.png"
          secondLogo="/assets/aws_logo.png"
          heading="Tee up your sales with Cisco & AWS"
          subline="Join us for this exclusive networking event in"
          sublinePlace="San Jose at Top Golf!"
          firstParagraph="Cisco and AWS provide a broad portfolio of joint offerings that help your
            customers migrate to the cloud, modernize and operate in a hybrid world. With
            26 Cisco solutions as well as EA’s now being offered through the AWS
            Marketplace, there is more opportunity than ever for Cisco and AWS to partner
            together."
          secondParagraph="Come learn about the full breadth and depth of what's available, in addition
            to some great Cisco year-end customer incentives to help you bring those deals
            across the line!"
          thirdParagraph="Network with your AWS and Cisco counterparts to effectively sell AWS and Cisco
          together."
          eventList={[
            "Get to know your Cisco and AWS sales colleagues in this interactive networking event.",
            "Build relationships with your account team counterparts.",
            "Build a plan for a successful 2023 with joint offerings available NOW.",
          ]}
          registerText="Register below to join us for a joint networking event at TopGolf!"
          eventDate="May 4, 2023"
          eventPlace="Top Golf Austin"
          eventAddress="2700 Esparanza Crossing"
          eventState="Austin, TX 78758"
          calendarLink="https://growtheventstorage.blob.core.windows.net/growtheventcontainer/join-cisco-and-aws-at-this-exclusive-event-at-san-jose-top-golf-thursday-may-4th.ics"
          addToCalendarButton="Add To Calendar"
          bottomLeftImage="/assets/CiscoWhiteLogoFooter.png"
          bottomRightImage="/assets/aws_logo_footer.png"
          submitButtonMessage="Submit"
          agendaDates={[
            "2:00 pm - 2:15 pm Registration and Networking",
            "2:15 pm - 3:00 pm Overview from AWS & Cisco Sales Leaders",
            "3:00 pm - 5:00 pm Networking and TopGolf",
          ]}
          banner="/assets/banner.jpeg"
          registrationMessage="Thanks for Registering for the Cisco/AWS TEST event."
        />
      );

    default:
      return <Home />;
  }
}

export default App;
