import { Preferences } from "@capacitor/preferences";
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import { arrowBackOutline, cameraOutline, qrCodeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ProfileQR.css";

const ProfileQR = () => {
  const history = useHistory();
  const [User, setUserData] = useState<any>({});
  const getData = async () => {
    let user = await Preferences.get({ key: "UserData" });
    if (user.value != null) {
      let Data = JSON.parse(user.value);
      setUserData(Data.data.data.data[0]);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol style={{ display: "flex" }}>
              <IonButton fill="clear" onClick={() => history.push("/")}>
                <IonIcon icon={arrowBackOutline} style={{ marginTop: "5px" }} />
              </IonButton>
              <h5
                style={{
                  textTransform: "capitalize",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                My QR Code
              </h5>
            </IonCol>
          </IonRow>
          <IonRow style={{ justifyContent: "center" }} class="profileQR">
            <IonImg src="assets/icon/Elipse.png" />
          </IonRow>
          <IonRow style={{ textAlign: "center" }}>
            <IonCol size="12">
              <h6>
                <b>
                  {User.FirstName} {User.LastName}
                </b>
              </h6>
              <p style={{ margin: "-7px" }}>{User.Jobtitle}</p>
            </IonCol>
          </IonRow>
          <IonRow style={{ justifyContent: "center" }}>
            <IonImg className="profileqr-img" src="assets/icon/qr-code.png" />
          </IonRow>
          <IonRow style={{ textAlign: "center" }}>
            <IonCol size="12">
              <IonButton className="profileQR-btn">
                <IonIcon icon={cameraOutline} />
                <IonLabel style={{ textTransform: "math-auto", marginLeft: "5px" }}>
                  Scan QR Code
                </IonLabel>
              </IonButton>
            </IonCol>
          </IonRow>
          {/* <PersonalQR name='John Doe' role='IT Recruiter' imgUrl='assets/icon/Elipse.png' /> */}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ProfileQR;
