import Http from "../services/Http.Service";
import { Contacts, EventContacts } from "../services/Api.Common";
import { Preferences } from "@capacitor/preferences";
import { ConfirmationEmail } from "./ConfirmationEmail";
import { QueryBySearchs } from "../services/EventPassportCode";
import { ConfirmationEmailForNotCiscoOrAmazon } from "./ConfirmationEmailForNotCiscoOrAmazon";
import { UserType } from "../interfaces/UserInterfaces";
import { IsAcceptedEmail } from "./IsAcceptedEmail";

// This is creating a record in Event Contacts Table.
export default async function EventContactRegistration(
  User: UserType,
  event: any,
  eventCode: string,
  attendeeID: string,
  templateId: string,
  notAcceptedEmailTemplateId: string,
  openToAllDomains = false,
  closedToAllDomains = false
) {
  let isAcceptedEmail = openToAllDomains ? true : IsAcceptedEmail(User.Email);
  isAcceptedEmail = closedToAllDomains ? false : isAcceptedEmail;
  const query = QueryBySearchs([{ FieldName: "Email", Code: User.Email }]);

  const ContactData = await Http.search(Contacts, query);

  const Req = {
    AttendeeName: User.Name,
    AttendeeEmail: User.Email,
    AttendeeMobile: User.Mobile,
    ...(User?.Company && { AttendeeCompany: User?.Company }),
    AttendeeTitle: User.JobTitle,
    ...(User?.DietaryRestrictions && { DietaryRestrictions: User?.DietaryRestrictions }),
    ...(!isAcceptedEmail && { Status: "Waitlist" }),

    // Impact Event
    ...(User?.AccountRegion && { AccountRegion: User?.AccountRegion }),
    ...(User?.ManagedAccount && { ManagedAccount: User?.ManagedAccount }),
    ...(User?.DiscussionsPercentage && { DiscussionsPercentage: User?.DiscussionsPercentage }),
    ...(User?.PreferredProduct && { PreferredProduct: User?.PreferredProduct }),
    ...(User?.CloudSpecialistCall && { CloudSpecialistCall: User?.CloudSpecialistCall }),
  };

  let Res = await Http.CreateUpdate(EventContacts, Req);

  if (ContactData.data.data.count > 0) {
    const ContactReq = {
      payload: {
        Contact: {
          id: ContactData.data.data.data[0].id,
          _ModuleAPIName: "Contacts",
        },
        Event: {
          id: event.id,
          _ModuleAPIName: "Events",
        },
      },
    };
    await Http.Update(EventContacts + "/" + Res.data.data.data._id, ContactReq);
  } else {
    const InsertReq = {
      // _RecordName: User.Name,
      FirstName: User.Name,
      LastName: "",
      Email: [
        {
          Email: User.Email,
          Primary: true,
        },
      ],
      Mobile: User.Mobile,
      Company: User.Company,
      JobTitle: User.JobTitle,
      Password: "",
      EventPassportCode: "",
    };

    let InsertRes = await Http.CreateUpdate(Contacts, InsertReq);

    const ContactReq = {
      payload: {
        Contact: {
          id: InsertRes.data.data.data.id,
          _ModuleAPIName: "Contacts",
        },
        Event: {
          id: event.id,
          _ModuleAPIName: "Events",
        },
      },
    };
    await Http.Update(EventContacts + "/" + Res.data.data.data._id, ContactReq);
  }

  Preferences.clear();

  await Preferences.set({
    key: "UserData",
    value: JSON.stringify(Res),
  });

  if (!Res.data.error) {
    if (isAcceptedEmail) {
      ConfirmationEmail(
        User.Email,
        `https://growthevents.com/event/${eventCode}&AddToCalendar=true`,
        templateId
      );
    } else {
      ConfirmationEmailForNotCiscoOrAmazon(
        User.Email,
        `https://growthevents.com/event/${eventCode}&AddToCalendar=true`,
        notAcceptedEmailTemplateId
      );
    }
  }

  return Res.data;
}
