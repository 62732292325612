import { IonButton, IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React, { useState } from "react";
import ProfileCard from "../../components/ProfileCard";
import CompanyList from "../Company/ComapnyList";

const EventCompanies = () => {
  const [viewList, setViewList] = useState(true);
  const [viewMap, setViewMap] = useState(false);

  const displayList = () => {
    setViewMap(false);
    setViewList(true);
  };

  const displayMap = () => {
    setViewList(false);
    setViewMap(true);
  };
  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12">
          <IonButton fill="outline" style={{ width: "20%" }} onClick={() => displayList()}>
            List
          </IonButton>
          <IonButton fill="outline" style={{ width: "20%" }} onClick={() => displayMap()}>
            Map
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          {viewList && (
            // <ProfileCard Name='Cisco' role='CyberSecurity' imgUrl='/assets/icon/Elipse.png' />
            <CompanyList />
          )}
          {viewMap && <IonImg style={{ width: "50%" }} src="/assets/basemap.png" />}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default EventCompanies;
