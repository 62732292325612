import { FormEvent, useEffect, useState } from "react";
import { QueryBySearchs } from "../../services/EventPassportCode";
import { useHistory, useParams } from "react-router";
import HttpService from "../../services/Http.Service";
import { EventCode } from "../../services/Api.Common";
import EventContactRegistration from "../../helpers/EventContactRegistration";
import { ModalProps } from "../Anaheim/AnaheimRegistration";
import { IonSpinner } from "@ionic/react";
import ImpactModal from "./ImpactModal";

const initialData = {
  FirstName: "",
  LastName: "",
  PositionTitle: "",
  CompanyEmail: "",
  AccountRegion: "",
  PhoneNumber: "",
  ManagedAccount: "",
  DiscussionsPercentage: "",
  PreferredProduct: [],
  CloudSpecialistCall: "",
};

const ImpactRegistration = () => {
  const history = useHistory();
  const { Code }: { Code: string } = useParams();
  const [event, setEvent] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [data, setData] = useState({ ...initialData });

  useEffect(() => {
    const getData = async () => {
      try {
        const eventQuery = QueryBySearchs([{ FieldName: "Code", Code: Code }]);
        const Event = await HttpService.search(EventCode, eventQuery);
        setEvent(Event.data.data.data[0]);
      } catch {}
    };

    getData();
  }, [Code]);

  const handleMultiSelect = (e: any) => {
    const selectedOptions = e.target.selectedOptions;
    const values = Array.from(selectedOptions).map((option: any) => option.value);
    //@ts-ignore
    setData((prev) => ({ ...prev, PreferredProduct: values }));
  };

  return (
    <>
      <div className="page impact-registration" id="registration">
        <div className="registration-title">
          <div className="title-lines-container">
            <div className="registration-title-line"></div>
            <span className="registration-title-p">Register</span>
            <div className="registration-title-line"></div>
          </div>
          <span className="registration-subtitle-p">
            Please fill out the information below to register
          </span>
        </div>
        <div className="registration-center-container">
          <p className="registration-center-text">
            Elevate Your Sales with AWS and Cisco! Secure you sales success and take control of your
            future by partnering with AWS.
          </p>
          <p className="registration-center-text mb-30">
            Don't leave anything to chance! Join us for an exclusive night of embracing the cloud
            revolution.
          </p>
          <p className="registration-center-text">
            You'll have the opportunity to be entered to win cutting-edge prizes. Register now to
            secure your spot and enter for a chance to win OnCloud
          </p>
          <p className="registration-center-text mb-30">
            Shoes or the latest Aviator Nation Sweat Set - the perfect blend of tech and style!
          </p>
          <p className="registration-center-text mb-50">
            Unlock the Power of the Cloud with Cisco solutions on AWS. We look forward to seeing you
            for a fun-filled night of tech and camaraderie!
          </p>
          <p className="registration-center-text mb-30" style={{ fontWeight: 800 }}>
            RSVP Soon! Registrations close August 18, 2023.
          </p>
          <p className="registration-exception mb-50">
            *Winners must be in attendance to be eligible for raffle prize
          </p>
          <span className="registration-expect-text">What You Can Expect</span>
          <b className="bold-text">Refreshments</b>
          <span className="registration-center-text mb-50" style={{ fontWeight: 500 }}>
            Hors d'oeuvres and drink service at Hazel Lounge Inside Mandalay Bay
          </span>
          <b className="bold-text">Branded Swag and Give Aways</b>
          <span className="registration-center-text">
            Cisco & AWS is thrilled to not only offer co-branded swag for all guests that come but
            the opportunity
          </span>
          <span className="registration-center-text mb-50">
            for over 13 guests to win exclusive prizes provided by Aviator Nation and OnCloud Shoes
          </span>
          <span className="registration-center-text mb-50" style={{ fontWeight: 500 }}>
            We eagerly anticipate your presence and join us to be On Cloud with Cisco & AWS.
          </span>
          <form onSubmit={handleSubmit} className="registration-form">
            <div className="first-form">
              <input
                value={data.FirstName}
                placeholder="First Name *"
                required
                className="form-input"
                onChange={(e: any) => setData((prev) => ({ ...prev, FirstName: e.target.value }))}
              />
              <input
                value={data.LastName}
                placeholder="Last Name *"
                required
                className="form-input"
                onChange={(e: any) => setData((prev) => ({ ...prev, LastName: e.target.value }))}
              />
              <input
                value={data.PositionTitle}
                placeholder="Position Title *"
                required
                className="form-input"
                onChange={(e: any) =>
                  setData((prev) => ({ ...prev, PositionTitle: e.target.value }))
                }
              />
              <input
                value={data.CompanyEmail}
                placeholder="Company Email *"
                required
                type="email"
                className="form-input"
                onChange={(e: any) =>
                  setData((prev) => ({ ...prev, CompanyEmail: e.target.value }))
                }
              />
              <input
                value={data.AccountRegion}
                placeholder="Account Region"
                className="form-input"
                onChange={(e: any) =>
                  setData((prev) => ({ ...prev, AccountRegion: e.target.value }))
                }
              />
              <input
                value={data.PhoneNumber}
                placeholder="Phone Number"
                className="form-input"
                onChange={(e: any) => setData((prev) => ({ ...prev, PhoneNumber: e.target.value }))}
              />
            </div>
            {/* <div className="forms-line mb-50"></div> */}
            <div className="additional-questions">
              <span className="preferred-product-label">
                What Account do you manage that has the highest potential for cloud
                services/products, based on conversations with the customer?
              </span>
              <textarea
                value={data.ManagedAccount}
                placeholder=""
                onChange={(e: any) =>
                  setData((prev) => ({ ...prev, ManagedAccount: e.target.value }))
                }
                required
                className="managed-account-input mb-50"
              />
              <select
                value={data.DiscussionsPercentage}
                className="discussions-percentage-select mb-50"
                onChange={(e: any) =>
                  setData((prev) => ({ ...prev, DiscussionsPercentage: e.target.value }))
                }
                required
              >
                <option value="" selected disabled>
                  What % of your discussions with the account in question above, are related to
                  cloud products/services?
                </option>
                <option value="0%">0%</option>
                <option value="25%">25%</option>
                <option value="50%">50%</option>
                <option value="75%">75%</option>
                <option value="100%">100%</option>
              </select>

              <span className="preferred-product-label">
                Does your customer have a preferred product/solution that they are looking to
                pursue?
                <span style={{ fontSize: "18px" }}>
                  {" "}
                  (Select multiple options with "Shift" or "Command")
                </span>
              </span>
              <select
                value={data.PreferredProduct}
                multiple
                className="preferred-product-select mb-50"
                onChange={(e: any) => handleMultiSelect(e)}
                required
              >
                <option value="Application Monitoring (AppD, TE, FSO)">
                  1. Application Monitoring (AppD, TE, FSO)
                </option>
                <option value="Security (Duo, Umbrella, SecureX)">
                  2. Security (Duo, Umbrella, SecureX)
                </option>
                <option value="Advanced Networking">3. Advanced Networking</option>
                <option value="Collaboration (WebEx)">4. Collaboration (WebEx)</option>
              </select>
              <select
                value={data.CloudSpecialistCall}
                className="cloud-especialist-call-select mb-50"
                required
                onChange={(e: any) =>
                  setData((prev) => ({ ...prev, CloudSpecialistCall: e.target.value }))
                }
              >
                <option value="" selected disabled>
                  Would a Joint call with a Cloud Specialist, to provide insights to your account on
                  cloud usage and or cloud spend need be of interest to you?
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <button className="registration-button">
              {submitting ? (
                <>
                  Registering
                  <IonSpinner name="lines" style={{ width: 20, height: 20, marginLeft: 10 }} />
                </>
              ) : (
                "Register"
              )}
            </button>
          </form>
        </div>
      </div>
      <div className="cisco-aws-footer">
        <img className="aws-img" src="/assets/aws_logo.png" alt="AWS" />
        <img className="cisco-img" src="/assets/CiscoWhiteLogo.png" alt="Cisco" />
      </div>
      <ImpactModal
        open={!!modal}
        dismiss={() => {
          setModal(null);
        }}
        title={modal?.title}
        description={modal?.description}
      />
    </>
  );

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setSubmitting(true);

    const firstUserData: any = {
      Name: `${data.FirstName} ${data.LastName}`,
      JobTitle: data.PositionTitle,
      Email: data.CompanyEmail,
      AccountRegion: data.AccountRegion,
      Mobile: data.PhoneNumber,
      ManagedAccount: data.ManagedAccount,
      DiscussionsPercentage: data.DiscussionsPercentage,
      PreferredProduct: data.PreferredProduct,
      CloudSpecialistCall: data.CloudSpecialistCall,
    };

    // Event Contact Reg - Auto waitlist
    try {
      const firstAttendeeData = await EventContactRegistration(
        firstUserData,
        event,
        Code,
        "",
        "d-aa3c334286e44db9afd97f789e9d664c",
        "d-8d832eb9d28f46c2b24c13fe0d0f1eef",
        false,
        true
      );

      if (!firstAttendeeData) {
        return setModal({
          title: "Registration Failed",
          description: "Something went wrong. Please try again.",
        });
      }

      setData({ ...initialData });
      // setModal({
      //   title: "Registration Completed",
      //   description:
      //     "Thank you for your submission. Your registration is pending availability. We will send you updates via email.",
      // });
      history.push("/event/20230827IMPCT/registered");
    } catch {
      setModal({
        title: "Registration Failed",
        description: "Something went wrong. Please try again.",
      });
    }

    setSubmitting(false);
  }
};

export default ImpactRegistration;
