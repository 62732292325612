import { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonButton } from "@ionic/react";
import "./Card.css";

interface CardProps {
  content: string;
  link: string;
  viewEvent: Function;
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <div>
      <>
        <IonCard className="custom-card">
          <IonCardHeader className="custom-head">
            <IonCardSubtitle>Nothing to search yet.</IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent style={{ color: "black" }} onClick={() => props.viewEvent()}>
            {props.content}
          </IonCardContent>

          <IonButton className="custom-btn" href={props.link}>
            Discover Events
          </IonButton>
        </IonCard>
      </>
    </div>
  );
};

export default Card;
