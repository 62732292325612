import http from "./Http.Common";

class ServicesAPI {
  getAll(ApiName: any) {
    return http.get(ApiName);
  }

  getById(ApiName: any, id: any) {
    return http.get(`${ApiName}/${id}`);
  }

  getFileURL(url = "", exp = 10000000) {
    return http.get(`/tenant/file/url?file=${url}&exp=${exp}`);
  }

  search(ApiName: any, data: any) {
    return http.put(`${ApiName}/search`, data);
  }

  getByBoj(ApiName: any, data: any) {
    return http.post(ApiName, data);
  }

  CreateUpdate(ApiName: any, data: any) {
    return http.post(ApiName, data);
  }

  Update(ApiName: any, data: any) {
    return http.put(ApiName, data);
  }

  UpdateReferenceRecords(moduleApiName: any, ApiName: any, id: any, data: any) {
    return http.put(
      `/tenant/crm/records/${moduleApiName}/${id}/updateReferenceRecords/${ApiName}`,
      data
    );
    // return http.put(`https://api.growthcloud.com/tenant/crm/records/Events/642eaafbf4bd9ef4db847ac7/updateReferenceRecords/Attendees`,data)
  }

  deleteByID(ApiName: any, id: any) {
    return http.delete(`${ApiName}/${id}`);
  }
}

export default new ServicesAPI();
