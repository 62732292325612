import { IonContent, IonPage } from "@ionic/react";
import "./AnaheimEvent.css";
import AnaheimLanding from "./AnaheimLanding";
import AnaheimRegistration from "./AnaheimRegistration";
import { createRef } from "react";

const AnaheimEvent = () => {
  const contentRef = createRef<HTMLIonContentElement>();

  function scrollToBottom() {
    contentRef.current?.scrollToBottom(1000);
  }

  return (
    <IonPage>
      <IonContent ref={contentRef}>
        <div className="ana-event">
          <AnaheimLanding scrollToBottom={scrollToBottom} />
          <AnaheimRegistration />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AnaheimEvent;
