import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonIcon,
  IonCardTitle,
  IonCardContent,
  IonImg,
  IonButton,
  IonLabel,
} from "@ionic/react";
import { shareSocial, bookmarkOutline, time, location } from "ionicons/icons";
import "./EventCard.css";
import feature_img1 from "../assets/home_screen_images/Featured_card_img1.jpg";
interface CardProps {
  title: string;
  subtitle: string;
  imgUrl: string;
  time: string;
  location: string;
  description: Function;
}

const EventCard: React.FC<CardProps> = (props) => {
  return (
    <>
      <IonCard className="custom_card" onClick={() => props.description()}>
        <div className="wrap-1">
          <IonImg src={props.imgUrl} />
          {/* <IonImg src={feature_img1} className='custom_card_img' /> */}
        </div>
        <div className="wrap-2">
          <IonCardHeader className="custom-card-header1">
            <div className="custom-card-header2">
              <IonCardSubtitle className="custom-card-subtitle">{props.subtitle}</IonCardSubtitle>
              <div className="card-header-icons">
                <IonButton fill="clear" size="small">
                  <IonIcon icon={shareSocial} />
                </IonButton>
                <IonButton fill="clear" size="small" className="custom_card_hide_icon">
                  <IonIcon icon={bookmarkOutline} />
                </IonButton>
              </div>
              {/* <a href="#"> <IonIcon style={{ color: '#3D9BE9' }} icon={shareSocial} /></a>
                            <a href="#"> <IonIcon style={{ marginLeft: '5px', color: '#3D9BE9' }} icon={bookmarkOutline} /></a> */}
            </div>
            <IonCardTitle>{props.title}</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            {/* <div className="custom-card-content" > */}
            <IonButton size="small" className="wrap-2-button">
              <IonIcon icon={time} />
              <IonLabel className="wrap-2-label">{props.time}</IonLabel>
            </IonButton>
            <IonButton size="small" className="wrap-2-button">
              <IonIcon icon={location} />
              <IonLabel className="wrap-2-label">{props.location}</IonLabel>
            </IonButton>
            {/* </div> */}

            {/* <a href="#"><IonIcon icon={time} />{props.time}</a>
                        <a href="#"><IonIcon icon={location} />{props.location}</a> */}
          </IonCardContent>
        </div>
      </IonCard>
    </>
  );
};

export default EventCard;
