export const MakeId = (length = 8) => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// export default MakeId;

// export function QueryBySearch(filter:[{FieldName:string,Code: string}]) {
//   let FilterGroup:[{}] = [{}]
//   FilterGroup.pop()
//   for (let i = 0; i < filter.length; i++) {
//     // text += cars[i] + "<br>";
//     FilterGroup.push({
//       Group: false,
//       Field: filter[i].FieldName,
//       Method: "equals",
//       Value: filter[i].Code
//   })

//   }

//   return {
//       Query: {
//           Fields: ["*"],
//           Filters: FilterGroup,
//       },
//   };
// }

export interface Filter {
  FieldName: string;
  Code: string;
}

interface FilterGroupItem {
  Group: boolean;
  Field: string;
  Method: string;
  Value: string;
}

interface Query {
  Fields: string[];
  Filters: FilterGroupItem[];
}

export function QueryBySearchs(filter: Filter[]): { Query: Query } {
  let FilterGroup: FilterGroupItem[] = [];
  for (let i = 0; i < filter.length; i++) {
    FilterGroup.push({
      Group: false,
      Field: filter[i].FieldName,
      Method: "equals",
      Value: filter[i].Code,
    });
  }

  return {
    Query: {
      Fields: ["*"],
      Filters: FilterGroup,
    },
  };
}

export function newDate(Data: any) {
  const StartDate = new Date(Data.Starts).toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
  const StartDateWithYear = new Date(Data.Starts).toLocaleDateString("en-us", {
    // weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  // const enddate = new Date(Data.Ends).toLocaleDateString('en-us', { weekday:"long", month:"long", day:"numeric"});
  const StartTime = new Date(Data.Starts).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });
  const EndTime = new Date(Data.Ends).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });
  return { StartDate, StartTime, EndTime, StartDateWithYear };
}
