import { IonSpinner } from "@ionic/react";
import { FormEvent, useEffect, useState } from "react";
import AnaheimModal from "./AnanheimModal";
import EventContactRegistration from "../../helpers/EventContactRegistration";
import HttpService from "../../services/Http.Service";
import { useParams } from "react-router";
import { EventCode } from "../../services/Api.Common";
import { QueryBySearchs } from "../../services/EventPassportCode";

export interface ModalProps {
  title: string;
  description: string;
}

const initialData = {
  name: "",
  company: "",
  email: "",
  title: "",
  dietaryRestrictions: "",
};

const AnaheimRegistration = () => {
  const [submitting, setSubmitting] = useState(false);
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [data, setData] = useState({ ...initialData });
  const [event, setEvent] = useState<any>(null);
  const { Code }: { Code: string } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const eventQuery = QueryBySearchs([{ FieldName: "Code", Code: Code }]);
        const Event = await HttpService.search(EventCode, eventQuery);
        setEvent(Event.data.data.data[0]);
      } catch {}
    };

    getData();
  }, [Code]);

  return (
    <>
      <div className="ana-reg">
        <h1 className="ana-reg-title">
          Security Excellence with AWS and Cisco <br />
          Accelerated, Actionable, Expert-led, for Today's Demands
        </h1>

        <div style={{ textAlign: "center", marginTop: "10px", fontWeight: 700, width: "70%" }}>
          You are cordially invited to an exclusive, private dinner hosted by AWS during AWS
          Re:Inforce in Anaheim, CA on June 13 at 5:30 PM. This gathering offers a unique
          opportunity to network and gain deeper insights into the world of AWS and Cisco security
          solutions.
        </div>
        <div className="ana-reg-info-container">
          <div
            style={{
              fontFamily: '"MigraExtralight", sans-serif',
              fontSize: "25px",
              marginBottom: "5px",
            }}
          >
            What You Can Expect
          </div>
          <div style={{ textAlign: "center", fontWeight: 600 }}>
            Dinner and drink service at Poppy and Seed Anaheim, CA
          </div>
          <div style={{ marginBottom: "10px", fontStyle: "italic", fontWeight: 500 }}>
            featuring an exclusive tasting menu by Chef Michael Reed
          </div>
          <div style={{ fontWeight: 600 }}>
            Transportation to and from the Anaheim Convention Center
          </div>
          <div
            style={{
              textAlign: "center",
              marginBottom: "10px",
              fontStyle: "italic",
              fontWeight: 500,
            }}
          >
            provided by Uber Business, we will have grouped vehicle transport pickup will be infront
            of the Arena Plaza on Katella Ave at 5:15pm our hosts will have signs to help guide you
            - this will be the same process for return
          </div>
          <div style={{ textAlign: "center", marginBottom: "10px", fontWeight: 600 }}>
            Branded Swag and Gift
          </div>
          <div style={{ textAlign: "center", fontWeight: 600 }}>
            Networking with AWS and Cisco Experts and Executives
          </div>
          <div
            style={{
              textAlign: "center",
              marginBottom: "10px",
              fontStyle: "italic",
              fontWeight: 500,
            }}
          >
            gain valuable opportunities and information into Security Services for your business
          </div>
        </div>
        <div style={{ fontWeight: 600, textAlign: "center", width: "100%", maxWidth: 600 }}>
          We eagerly anticipate your presence at this private dinner event; where networking,
          knowledge sharing, and fine dining await.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 1000,
            marginTop: "10px",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex", justifyContent: "space-between", gap: 10 }}>
              <input
                value={data.name}
                placeholder="Full Name *"
                className="ana-reg-input"
                onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
                required
              />
              <input
                value={data.email}
                placeholder="Email *"
                type="email"
                className="ana-reg-input"
                onChange={(e) => setData((prev) => ({ ...prev, email: e.target.value }))}
                required
              />
              <input
                value={data.company}
                placeholder="Company"
                className="ana-reg-input"
                onChange={(e) => setData((prev) => ({ ...prev, company: e.target.value }))}
              />

              <input
                value={data.title}
                placeholder="Title"
                className="ana-reg-input"
                onChange={(e) => setData((prev) => ({ ...prev, title: e.target.value }))}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
              <input
                value={data.dietaryRestrictions}
                placeholder="Dietary Restrictions (If any)"
                className="ana-reg-input"
                onChange={(e) =>
                  setData((prev) => ({ ...prev, dietaryRestrictions: e.target.value }))
                }
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <button className="ana-reg-button" disabled={submitting || !data.name || !data.email}>
                {submitting ? (
                  <>
                    Registering
                    <IonSpinner name="lines" style={{ width: 20, height: 20, marginLeft: 10 }} />
                  </>
                ) : (
                  "Register"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <AnaheimModal
        open={!!modal}
        dismiss={() => {
          setModal(null);
        }}
        title={modal?.title}
        description={modal?.description}
      />
    </>
  );

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setSubmitting(true);

    const userData: any = {
      Name: data.name,
      Email: data.email,
      Mobile: "",
      Company: data.company,
      JobTitle: data.title,
      DietaryRestrictions: data.dietaryRestrictions,
    };

    // Event Contact Reg - Auto waitlist
    try {
      const attendeeData = await EventContactRegistration(
        userData,
        event,
        Code,
        "",
        "",
        "d-7d0fb08b324e4c8fbe10dcab32e01f51",
        false,
        true
      );

      if (!attendeeData) {
        return setModal({
          title: "Registration Failed",
          description: "Something went wrong. Please try again.",
        });
      }

      setData({ ...initialData });
      setModal({
        title: "Registration Completed",
        description:
          "Thank you for your submission. Your registration is pending availability. We will send you updates via email.",
      });
    } catch {
      setModal({
        title: "Registration Failed",
        description: "Something went wrong. Please try again.",
      });
    }

    setSubmitting(false);
  }
};

export default AnaheimRegistration;
