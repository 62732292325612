import {
  IonContent,
  IonHeader,
  IonCheckbox,
  IonPage,
  IonTitle,
  IonToolbar,
  IonImg,
  IonInput,
  IonButton,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import "../Registration/Registration.css";

import { mail } from "ionicons/icons";
import { lockClosed } from "ionicons/icons";
import { UserSchema } from "../../services/Types";
import Http from "../../services/Http.Service";
import { Contacts } from "../../services/Api.Common";
import { Filter, QueryBySearchs } from "../../services/EventPassportCode";
import { useHistory } from "react-router";
import { Preferences } from "@capacitor/preferences";

const LoginPage: React.FC = () => {
  // useEffect(() => localStorage.setItem('Status','Login'))

  // interface Filter {
  //     FieldName: string;
  //     Code: string;
  // }

  const [User, setUser] = useState(UserSchema);
  // const [login,setLogin]=useState({})
  const { Email, Password } = User;
  const history = useHistory();

  const onValueChange = (e: any) => {
    console.log(e.target.value);
    setUser({ ...User, [e.target.name]: e.target.value });
  };

  // for the filter
  const myFilters: Filter[] = [
    { FieldName: "Email", Code: User.Email },
    // { FieldName: "EventPassportCode", Code: User.EventPassportCode },
  ];

  const query = QueryBySearchs(myFilters);
  const onVerify = async () => {
    const event = await Http.search(Contacts, query);
    console.log(event);
    if (event.data.data.pagination.TotalRecords < 1) {
      alert("user is not register");
      window.location.reload();
    }
    if (event.data.data.data[0].Password !== User.Password) {
      alert("Email & Confermation code is not match");
      window.location.reload();
    }
    if (event.data.data.data[0].Password === User.Password) {
      localStorage.setItem("Status", "Login");
      // Preferences.clear()
      await Preferences.set({
        key: "UserData",
        value: JSON.stringify(event),
      });
      history.push("/");
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ padding: "0px", height: "100vh" }}>
          <IonRow>
            <IonCol size="12" size-lg="6" style={{ padding: "0px" }} className="login_img"></IonCol>
            <IonCol size="12" size-lg="6" className="Login_content_box">
              <div style={{ width: "100%" }}>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <IonImg
                      className="custom_logo1"
                      src="assets/home_screen_images/Logo.svg"
                      alt="Logo"
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <IonLabel style={{ fontSize: "18px", marginTop: "10px" }}>
                      Your event passport
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <IonLabel
                      style={{
                        fontSize: "28px",
                        fontWeight: "bold",
                        marginTop: "40px",
                      }}
                    >
                      Login to your account
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <div className="login_form">
                      <IonItem lines="none" style={{ position: "relative" }}>
                        <IonIcon icon={mail} className="custom_icons" />
                        <IonInput
                          onIonChange={(e) => onValueChange(e)}
                          value={Email}
                          name="Email"
                          className="custom_input_filed Login_Input"
                          placeholder="Email*"
                          type="text"
                        />
                      </IonItem>
                      <IonItem lines="none" style={{ position: "relative" }}>
                        <IonIcon icon={lockClosed} className="custom_icons" />
                        <IonInput
                          onIonChange={(e) => onValueChange(e)}
                          value={Password}
                          name="Password"
                          className="custom_input_filed Login_Input"
                          placeholder="Password*"
                          type="password"
                        />
                      </IonItem>

                      <div className="custom_check">
                        <div>
                          <input
                            type="checkbox"
                            style={{
                              marginRight: "5px",
                              border: "1px solid #3d9be9",
                            }}
                          />
                          <IonLabel>Remember me</IonLabel>
                          {/* Rembember me */}
                        </div>
                        <div>
                          <IonLabel style={{ cursor: "pointer" }} onClick={() => alert("Hello")}>
                            Forgot Password?
                          </IonLabel>
                          {/* Forgot Password? */}
                        </div>
                      </div>

                      <div style={{ padding: "0 16px" }}>
                        <IonButton
                          onClick={onVerify}
                          className="secondary_btn"
                          style={{ width: "100%", height: "50px" }}
                        >
                          Login
                        </IonButton>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex", marginTop: "20px" }}
                  >
                    <div
                      style={{
                        color: "#a3a3a3",
                        display: "flex",
                        overflow: "hidden",
                      }}
                    >
                      _______________{" "}
                      <span style={{ color: "#000", fontWeight: "bold" }}> or </span>
                      _______________
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <div className="logo-container">
                      <IonImg src="assets/icon/socials/Amazon.png" alt="Amazon" />
                      <IonImg src="assets/icon/socials/Google.png" alt="Google" />
                      <IonImg src="assets/icon/socials/Microsoft.png" alt="Microsoft" />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center already_register"
                    style={{ display: "flex", marginTop: "50px" }}
                  >
                    <IonLabel style={{ fontWeight: "bold" }}>
                      New to GrowthEvent? <a href="/registration">Register</a>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{
                      display: "flex",
                      marginTop: "25px",
                      textAlign: "center",
                    }}
                  >
                    <IonLabel>
                      By logging in you accept the{" "}
                      <span style={{ fontWeight: "bold" }}>Terms and Conditions</span> and you agree
                      with our <span style={{ fontWeight: "bold" }}>Privacy Policy</span>
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
