import {
  IonPage,
  IonButton,
  IonImg,
  IonItem,
  IonLabel,
  IonIcon,
  IonList,
  IonListHeader,
  IonContent,
  IonSplitPane,
} from "@ionic/react";
import {
  businessOutline,
  calendarOutline,
  logOutOutline,
  pencil,
  person,
  settingsSharp,
} from "ionicons/icons";
import "./Sidebar.css";
const Sidebar: React.FC = () => {
  return (
    <IonSplitPane className="ion-sidebar">
      <IonContent>
        <div className="user-profile">
          {/* <IonImg src="" style={{ maxWidth: "80px" }}></IonImg> */}
          <img
            style={{ borderRadius: "50%", width: "70px", marginBottom: "10px" }}
            alt="Silhouette of a person's head"
            src="https://ionicframework.com/docs/img/demos/avatar.svg"
          />
          <IonLabel>Jane Doe</IonLabel>
        </div>
        <IonList lines="none">
          <IonListHeader>
            <IonLabel>
              <p>Overview</p>
            </IonLabel>
          </IonListHeader>
          <IonItem>
            <IonButton
              class="sidebar_tabs"
              fill="clear"
              href="/Events"
              target="_parent"
              className="ion-button"
            >
              <IonIcon icon={calendarOutline} />
              <IonLabel className="ion-label">Events</IonLabel>
            </IonButton>
          </IonItem>
          <IonItem>
            <IonButton class="sidebar_tabs" fill="clear" className="ion-button">
              <IonIcon icon={businessOutline} />
              <IonLabel className="ion-label">Companies</IonLabel>
            </IonButton>
          </IonItem>
          <IonItem>
            <IonButton class="sidebar_tabs" fill="clear" className="ion-button">
              <IonIcon icon={person} />
              <IonLabel className="ion-label">Contacts</IonLabel>
            </IonButton>
          </IonItem>
          <IonItem>
            <IonButton class="sidebar_tabs" fill="clear" className="ion-button">
              <IonImg src="assets/icon/passport.png"></IonImg>
              <IonLabel className="ion-label">Passport</IonLabel>
            </IonButton>
          </IonItem>

          <IonListHeader>
            <IonLabel>
              <p>Account</p>
            </IonLabel>
          </IonListHeader>
          <IonItem>
            <IonButton class="sidebar_tabs" fill="clear" className="ion-button">
              <IonIcon icon={pencil} />
              <IonLabel className="ion-label">Edit Profile</IonLabel>
            </IonButton>
          </IonItem>
          <IonItem>
            <IonButton class="sidebar_tabs" fill="clear" className="ion-button">
              <IonIcon icon={settingsSharp} />
              <IonLabel className="ion-label">Privacy Settings</IonLabel>
            </IonButton>
          </IonItem>
          <IonItem>
            <IonButton class="sidebar_tabs" fill="clear" className="ion-button">
              <IonIcon icon={logOutOutline} />
              <IonLabel className="ion-label">Log Out</IonLabel>
            </IonButton>
          </IonItem>
        </IonList>
      </IonContent>
    </IonSplitPane>
  );
};

export default Sidebar;
