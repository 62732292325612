import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonImg,
} from "@ionic/react";
import { mail } from "ionicons/icons";
import { useState } from "react";
import { UserSchema } from "../../services/Types";
import Http from "../../services/Http.Service";
import { Contacts } from "../../services/Api.Common";
import { QueryBySearchs, Filter } from "../../services/EventPassportCode";
import { useHistory } from "react-router";
import { Preferences } from "@capacitor/preferences";

const EventDetailsLogin = () => {
  // interface Filter {
  //     FieldName: string;
  //     Code: string;
  // }

  const [User, setUser] = useState(UserSchema);
  // const [login,setLogin]=useState({})
  const { Email, EventPassportCode } = User;

  const history = useHistory();

  const onValueChange = (e: any) => {
    console.log(e.target.value);
    setUser({ ...User, [e.target.name]: e.target.value });
  };

  // for the filter
  const myFilters: Filter[] = [
    { FieldName: "Email", Code: User.Email },
    // { FieldName: "EventPassportCode", Code: User.EventPassportCode },
  ];

  const query = QueryBySearchs(myFilters);
  const onVerify = async () => {
    const event = await Http.search(Contacts, query);
    console.log(event);
    if (event.data.data.pagination.TotalRecords < 1) {
      alert("user is not register");
      window.location.reload();
    }
    if (event.data.data.data[0].EventPassportCode !== User.EventPassportCode) {
      alert("Email & Confermation code is not match");
      window.location.reload();
    }
    if (event.data.data.data[0].EventPassportCode === User.EventPassportCode) {
      await Preferences.set({
        key: "UserData",
        value: JSON.stringify(event),
      });
      localStorage.setItem("Status", "Login");
      history.push("/events");
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div style={{ display: "flex", height: "100vh", alignItems: "center" }}>
          <IonGrid style={{ padding: "0" }}>
            <IonRow>
              <IonCol size="12" className="custom_col">
                <h2
                  style={{
                    fontWeight: "bolder",
                    marginBottom: "0px",
                    fontSize: "28px",
                  }}
                >
                  Event Details
                </h2>
              </IonCol>
              <IonCol size="12" className="custom_col">
                <span style={{ lineHeight: "2" }}>
                  Enter the email address you used to register for the event and the <br />{" "}
                  confirmation code you received.
                </span>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <div className="event_attendee_form">
                  <IonItem lines="none">
                    <IonIcon icon={mail} className="register_events_icons" />
                    <IonInput
                      onIonChange={(e) => onValueChange(e)}
                      value={Email}
                      name="Email"
                      className="custom_input_filed event_landing_input "
                      placeholder="Email*"
                      type="email"
                    />
                  </IonItem>
                  <IonItem lines="none">
                    <IonImg src="assets/icon/confirmcode.svg" className="register_events_icons" />
                    <IonInput
                      onIonChange={(e) => onValueChange(e)}
                      value={EventPassportCode}
                      name="EventPassportCode"
                      className="custom_input_filed event_landing_input"
                      placeholder="Confirmation number"
                      type="email"
                    />
                  </IonItem>
                  <IonItem lines="none" style={{ marginTop: "20px" }}>
                    <IonButton
                      onClick={() => onVerify()}
                      className="secondary_btn event_attendee_btn"
                      id="open-waitlist-modal"
                      expand="block"
                    >
                      Login
                    </IonButton>
                  </IonItem>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EventDetailsLogin;
