// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/the_telegram.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chicago_event_banner_img{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important;\n    background-position: center;\n}\n\n.chicago_email_heading{\n    text-align: center;\n    color: #2fbceb !important;\n    font-size: 21px !important;\n}\n\n.chicago_second_heading{\n    text-align: center;\n}\n\n.chicago_event_list li{\n    padding: 0px;\n}\n\n.chicago_para_heading{\n    font-size: 20px !important;\n}\n\n.chicago_email_icon{\n    font-size: 35px !important;\n}\n\n.add_cal a{\n    margin-top: 0px;\n    height: 40px;\n    display: inline-flex;\n}", "",{"version":3,"sources":["webpack://./src/pages/User/ChicagoEvent.css"],"names":[],"mappings":"AAAA;IACI,oEAAiE;IACjE,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,oBAAoB;AACxB","sourcesContent":[".chicago_event_banner_img{\n    background-image: url(/public/assets/the_telegram.png) !important;\n    background-position: center;\n}\n\n.chicago_email_heading{\n    text-align: center;\n    color: #2fbceb !important;\n    font-size: 21px !important;\n}\n\n.chicago_second_heading{\n    text-align: center;\n}\n\n.chicago_event_list li{\n    padding: 0px;\n}\n\n.chicago_para_heading{\n    font-size: 20px !important;\n}\n\n.chicago_email_icon{\n    font-size: 35px !important;\n}\n\n.add_cal a{\n    margin-top: 0px;\n    height: 40px;\n    display: inline-flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
