import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonRouterOutlet,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Header.css";
import "../theme/theme.css";
import {
  notifications,
  chevronDown,
  calendarOutline,
  businessOutline,
  person,
  document,
  pencil,
  settings,
  logOut,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Preferences } from "@capacitor/preferences";

const Header: React.FC = () => {
  const history = useHistory();
  const [toggleLogin, setLogin] = useState(false);
  const [User, setUserData] = useState<any>({});

  const getData = async () => {
    let user = await Preferences.get({ key: "UserData" });
    if (user.value != null) {
      let Data = JSON.parse(user.value);
      setUserData(Data.data.data.data[0]);
    }
  };

  useEffect(() => {
    let status = localStorage.getItem("Status");
    if (status === "Login") {
      setLogin(true);
    } else {
      setLogin(false);
    }
    getData();
  }, []);

  const LogOut = () => {
    localStorage.setItem("Status", "Logout");

    window.location.reload();
  };

  return (
    <>
      {/* <IonPage id="main-contenet"> */}
      <IonMenu contentId="main-content">
        <IonContent className="ion-padding">
          <IonButton href="/login" class="primary_btn" style={{ display: "block" }}>
            Login
          </IonButton>
          <IonButton href="/registration" class="secondary_btn" style={{ display: "block" }}>
            Register
          </IonButton>
        </IonContent>
      </IonMenu>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <IonImg
              className="logo1"
              src="assets/home_screen_images/growth_logo.svg"
              style={{ width: "35px" }}
            />
            <IonImg
              className="logo2"
              src="assets/home_screen_images/Logo.svg"
              style={{ width: "215px" }}
            />
          </IonTitle>
          {!toggleLogin ? (
            <IonButtons slot="end">
              <IonButton href="/login" class="primary_btn btn_1">
                Login
              </IonButton>
              <IonButton href="/registration" class="secondary_btn btn_2">
                Register
              </IonButton>
              <IonMenuButton class="menu_btn" color={"success"}></IonMenuButton>
            </IonButtons>
          ) : (
            <IonButtons slot="end">
              <IonIcon className="notification_icon" icon={notifications} />
              <IonItem lines="none" style={{ width: "160px" }}>
                <IonAvatar slot="start" className="custom_avatar" style={{ marginRight: "0px" }}>
                  <img
                    alt="Silhouette of a person's head"
                    src="https://ionicframework.com/docs/img/demos/avatar.svg"
                  />
                </IonAvatar>
                <IonButton id="click-trigger" style={{ textTransform: "math-auto" }}>
                  {User.FirstName} {User.LastName}
                  <IonIcon style={{ marginLeft: "10px" }} icon={chevronDown} />
                </IonButton>
                <IonPopover trigger="click-trigger" triggerAction="click">
                  <IonContent class="ion-padding">
                    <IonList class="custom_profile_popover">
                      <IonListHeader>
                        <IonLabel>Overview</IonLabel>
                      </IonListHeader>
                      <IonItem lines="none">
                        <IonLabel onClick={() => history.push("/events")}>
                          <IonIcon icon={calendarOutline} />
                          Events
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <IonIcon icon={businessOutline} />
                          Companies
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <IonIcon icon={person} />
                          Contacts
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <IonIcon icon={document} />
                          Passport
                        </IonLabel>
                      </IonItem>
                      <IonListHeader>
                        <IonLabel>Account</IonLabel>
                      </IonListHeader>
                      <IonItem lines="none">
                        <IonLabel onClick={() => history.push("/edit/profile")}>
                          <IonIcon icon={pencil} />
                          Edit Profile
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel onClick={() => history.push("/privacy/setting")}>
                          <IonIcon icon={settings} />
                          Privacy Settings
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel onClick={() => LogOut()}>
                          <IonIcon icon={logOut} />
                          Log Out
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonContent>
                </IonPopover>
              </IonItem>
              <IonImg
                style={{ width: "30px" }}
                src="assets/home_screen_images/QR.svg"
                onClick={() => history.push("/qr")}
              />
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      {/* </IonPage> */}
    </>
  );
};

export default Header;
