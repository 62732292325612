import { IonButton, IonIcon, IonModal } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

interface Props {
  open: boolean;
  dismiss: () => void;
}

const EventFinishedModal = ({ open, dismiss }: Props) => {
  return (
    <IonModal backdropDismiss={false} isOpen={open} id="confirmation-modal">
      <div style={{ height: "200px", width: "400px", textAlign: "center" }} className="wrapper">
        <div className="btn_close_wrapper">
          <IonButton fill="clear" onClick={dismiss} className="btn_close">
            <IonIcon size="large" icon={closeOutline} />
          </IonButton>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 className="modal_header">Event has ended</h1>
          <p
            style={{
              textAlign: "center",
              margin: "20px",
              lineHeight: "25px",
            }}
          >
            You can no longer register for this event.
          </p>
        </div>
      </div>
    </IonModal>
  );
};

export default EventFinishedModal;
