import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTextarea,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  calendarClear,
  calendarClearOutline,
  closeOutline,
  locationOutline,
  mail,
  mapOutline,
  pinOutline,
  stopwatchOutline,
  watchOutline,
} from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import "./EventDetail.css";
import { Preferences } from "@capacitor/preferences";
import { newDate } from "../../services/EventPassportCode";
import Http from "../../services/Http.Service";
import Map from "../Maps/Maps";
import { useHistory } from "react-router";

// interface eventType {
//     editInfo: Function
// }

const EventDetail: React.FC = () => {
  const registerModal = useRef<HTMLIonModalElement>(null);

  const dismiss = () => {
    registerModal.current?.dismiss();
  };

  //  state set for the event and the contact values
  const [EventData, setEventData] = useState<any>({});
  const [imageUrl, setImageUrl] = useState<any>({});
  const [SponsorImg, setSponsorImg] = useState<string>();
  const history = useHistory();

  const [coordinatesData, setCoordinatesData] = useState({
    lat: 0.0,
    long: 0.0,
  });

  // get the all the data ..

  useEffect(() => {
    if (EventData.Coordinates) {
      setCoordinatesData({
        lat: parseFloat(EventData.Coordinates?.lat),
        long: parseFloat(EventData.Coordinates?.long),
      });
      console.log(coordinatesData);
    }
  }, [EventData]);

  useEffect(() => {
    let event = Preferences.get({ key: "event" }).then((data) => {
      if (data.value == null) {
        history.push("/");
      }
    });
  }, []);

  // get the all the data ..
  const Data = async () => {
    let event = await Preferences.get({ key: "event" });
    let imageUrl = await Preferences.get({ key: "imageUrl" });

    console.log(event.value);

    //  condition for the value is not null
    if (event.value != null) {
      setEventData(JSON.parse(event.value));
    }
    if (imageUrl.value != null) {
      setImageUrl(imageUrl.value);
    }
  };
  const { StartDate, StartTime, EndTime } = newDate(EventData);
  let SponsorImage = "";
  // const { StartDate, StartTime, EndTime } = newFunction(EventData);

  useEffect(() => {
    Data();
  }, []);

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonImg style={{ width: "100%" }} src={imageUrl} />
          <IonCol size="12">
            <h1 style={{ fontWeight: 700, fontSize: "32px" }}>{EventData.Name}</h1>
          </IonCol>
        </IonRow>
        {EventData.Sponsors ? (
          EventData.Sponsors.map((Sponsor: any, index: number) => {
            console.log(Sponsor);

            Http.getFileURL(Sponsor.SponsorCompany.CompanyLogo.File).then((data) => {
              if (data && data.data && data.data.data && data.data.data.url) {
                SponsorImage = data.data.data.url;
                setSponsorImg(SponsorImage);
              }
            });
            return (
              <IonRow key={index}>
                <IonCol size="12" sizeLg="3">
                  <IonCard class="detail-card">
                    <IonCardContent>
                      <IonItem lines="none">
                        <IonThumbnail slot="start">
                          <IonImg src={SponsorImg} alt="SponsorImage" />
                        </IonThumbnail>
                        <ul className="detail-list">
                          <li className="first">{Sponsor.SponsorCompany._RecordName}</li>
                          <li className="second">{Sponsor.SponsorshipLevel}</li>
                        </ul>
                      </IonItem>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            );
          })
        ) : (
          <></>
        )}

        <IonRow class="ion-padding">
          <IonCol size="10" sizeMd="12" class="ion-padding-top">
            <IonLabel class="event-calander" style={{ fontWeight: "bold" }}>
              <IonIcon slot="start" icon={calendarClearOutline} class="detail-icon"></IonIcon>{" "}
              &nbsp;
              {StartDate}
            </IonLabel>
            {/* <IonLabel style={{ display: 'block' }}>
                            <a href="#" style={{ marginLeft: '26px' }} className='event-link'>Add to calendar</a>
                        </IonLabel> */}
          </IonCol>
          <IonCol size="10" sizeMd="12" class="ion-padding-top">
            <IonLabel style={{ fontSize: "18px" }}>
              <IonIcon slot="start" icon={stopwatchOutline} class="detail-icon"></IonIcon>
              &nbsp;
              {StartTime} - {EndTime}
            </IonLabel>
          </IonCol>
          <IonCol size="10" sizeMd="12" class="ion-padding-top">
            <IonLabel class="event-calander" style={{ fontWeight: "bold" }}>
              <IonIcon slot="start" icon={locationOutline} class="detail-icon"></IonIcon>
              &nbsp;
              <b> {EventData.Location ? EventData.Location.PlainText : ""} </b>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow class="ion-padding">
          <IonCol sizeMd="8" class="ion-padding-bottom">
            <IonTitle class="ion-no-padding" style={{ fontWeight: "bold" }}>
              About
            </IonTitle>
            <IonText>{EventData.Description ? EventData.Description.PlainText : ""}</IonText>
          </IonCol>
        </IonRow>
        <IonRow class="ion-padding">
          <IonCol size="12" sizeMd="8">
            <IonTitle class="ion-no-padding" style={{ fontWeight: "bold" }}>
              Location
            </IonTitle>
            {/* <IonImg src="/assets/basemap.png" /> */}
            <div className="map-main">
              <Map lat={coordinatesData.lat} long={coordinatesData.long}></Map>
            </div>
          </IonCol>
          <IonCol sizeMd="12" class="ion-padding-top" style={{ fontSize: "13px" }}>
            <IonLabel>
              <b> {EventData.Location ? EventData.Location.PlainText : ""} </b>
            </IonLabel>
          </IonCol>
        </IonRow>
        {/* <IonRow class="ion-padding">
                    <IonCol>
                        <IonButton id="open-cancel-registeration" className="primary_btn">Cancel Registration</IonButton>
                        <IonButton className="secondary_btn" style={{ height: '35px' }} onClick={() => props.editInfo()} >Edit Your Information</IonButton>
                    </IonCol>
                </IonRow> */}
      </IonGrid>
      <IonModal id="cancel-registeration" ref={registerModal} trigger="open-cancel-registeration">
        <div className="wrapper">
          <div className="btn_close_wrapper">
            <IonButton fill="clear" onClick={dismiss} className="btn_close">
              <IonIcon icon={closeOutline} />
            </IonButton>
          </div>
          <h1 className="modal_header">Cancel Registration</h1>
          <p style={{ textAlign: "center", marginBottom: "25px" }}>
            We're sorry to hear that you won't be able to make it to the event. <br />
            Could you please let us know the reason for your cancelation?
          </p>
          <p style={{ textAlign: "center", marginBottom: "10px" }}>
            <b>Your feedback will help us improve our events in the future.</b>
          </p>
          <div style={{ marginBottom: "10px" }}>
            <IonTextarea rows={5} className="textarea-event"></IonTextarea>
          </div>
          <IonGrid>
            <IonRow style={{ textAlign: "center" }}>
              <IonCol className="ion-justify-content-center" style={{ display: "flex" }}>
                <IonButton onClick={dismiss} className="primary_btn">
                  Cancel
                </IonButton>
                <IonButton style={{ height: "35px" }} className="secondary_btn">
                  Confirm
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonModal>
    </>
  );
};

export default EventDetail;
