import { Preferences } from "@capacitor/preferences";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from "@ionic/react";
import { arrowBackOutline, time, location } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EventDetail from "./EventDetail";
import ProfileCard from "../../components/ProfileCard";
import EventCompanies from "./EventCompanies";
import EventAttendees from "./EventAttendees";

const EventInfo = () => {
  const history = useHistory();

  const [Segment, setSegment] = useState("detail");
  const [Detail, setDetail] = useState(true);
  const [Schedule, setSchedule] = useState(false);
  const [Attendees, setAttendees] = useState(false);
  const [Companies, setCompanies] = useState(false);

  const [Event, setEvent] = useState<any>({});
  const [ImageUrl, setImageUrl] = useState<any>({});

  const onSegmentChanged = (e: string | undefined) => {
    if (e === "detail") {
      setSegment(e);
      setDetail(true);
      setSchedule(false);
      setAttendees(false);
    } else if (e === "schedule") {
      setSegment(e);
      setSchedule(true);
      setDetail(false);
      setAttendees(false);
    } else if (e === "attendees") {
      setSegment(e);
      setAttendees(true);
      setSchedule(false);
      setDetail(false);
      setCompanies(false);
    } else if (e === "companies") {
      setSegment(e);
      setAttendees(false);
      setSchedule(false);
      setDetail(false);
      setCompanies(true);
    }
  };

  const getData = async () => {
    let event = await Preferences.get({ key: "event" });
    let imageUrl = await Preferences.get({ key: "imageUrl" });

    if (event.value != null) {
      setEvent(JSON.parse(event.value));
    }
    if (imageUrl.value != null) {
      setImageUrl(imageUrl.value);
    }
  };

  const StartDate = new Date(Event.Starts).toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  useEffect(() => {
    getData();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol style={{ display: "flex" }}>
              <IonButton fill="clear" onClick={() => history.push("/events")}>
                <IonIcon icon={arrowBackOutline} style={{ marginTop: "5px" }} />
              </IonButton>
              <h5
                style={{
                  textTransform: "capitalize",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {Event.Name}
              </h5>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="9" sizeLg="3">
              <IonToolbar className="custom_toolbar">
                <IonSegment
                  color="success"
                  value={Segment}
                  onIonChange={(e) => onSegmentChanged(e.detail.value)}
                >
                  <IonSegmentButton value="detail" className="attendee-segment-button">
                    <IonLabel>Detail</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="schedule" className="attendee-segment-button">
                    <IonLabel>Schedule</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="attendees" className="attendee-segment-button">
                    <IonLabel>Attendees</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="companies" className="attendee-segment-button">
                    <IonLabel>Companies</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonToolbar>
            </IonCol>
          </IonRow>
          {Detail && <EventDetail />}
          {Schedule && (
            <>
              <IonRow>
                <IonCol>
                  <IonImg src={ImageUrl} alt="hero-image" />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <h2 className="text_style" style={{ fontSize: "32px" }}>
                    {Event.Name}
                  </h2>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <b style={{ marginBottom: "10px" }}>{StartDate}</b>
                </IonCol>
              </IonRow>
              {Event.EventSchedule.map((schedule: any) => (
                <IonRow>
                  <IonCol col-sizeSm="12" size="3" sizeXs="12" sizeXl="3" style={{ padding: "0" }}>
                    <IonCard
                      className="schedule_card"
                      onClick={() => history.push("/schedule/details")}
                    >
                      <IonCardHeader className="schedule-card-header1">
                        <IonCardSubtitle className="schedule-card-subtitle">
                          11:00 AM - 11:30 PM
                        </IonCardSubtitle>
                        <IonCardTitle>
                          <h6 style={{ fontWeight: "bold" }} className="attendee-heading">
                            {schedule.Name}
                          </h6>
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent style={{ display: "flex", color: "black" }}>
                        <div className="schedule_block">
                          <IonIcon icon={time} />
                          <IonLabel className="schedule_label">{schedule.Duration} min</IonLabel>
                        </div>
                        <div className="schedule_block">
                          <IonIcon icon={location} />
                          <IonLabel className="schedule_label">{schedule.Location}</IonLabel>
                        </div>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
              ))}
            </>
          )}
          {Attendees && <EventAttendees />}
          {Companies && <EventCompanies />}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EventInfo;
