import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTextarea,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  calendarClear,
  calendarClearOutline,
  closeOutline,
  locationOutline,
  mail,
  mapOutline,
  pinOutline,
  stopwatchOutline,
  watchOutline,
} from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { Preferences } from "@capacitor/preferences";

const Details: React.FC = (props) => {
  //  state set for the event and the contact values
  const [EventData, setEventData] = useState<any>({});
  const [imageUrl, setImageUrl] = useState<any>({});

  // get the all the data ..
  const Data = async () => {
    let event = await Preferences.get({ key: "event" });
    let imageUrl = await Preferences.get({ key: "imageUrl" });

    console.log(imageUrl);
    // console.log(event.value)

    //  condition for the value is not null
    if (event.value != null) {
      setEventData(JSON.parse(event.value));
    }
    if (imageUrl.value != null) {
      setImageUrl(imageUrl.value);
    }
  };

  const StartDate = new Date(EventData.Starts).toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
  // const enddate = new Date(EventData.Ends).toLocaleDateString('en-us', { weekday:"long", month:"long", day:"numeric"});
  const StartTime = new Date(EventData.Starts).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });
  const EndTime = new Date(EventData.Ends).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });

  useEffect(() => {
    Data();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow style={{ justifyContent: "center" }}>
            <IonCol size="10">
              <IonTitle>{EventData.Name}</IonTitle>
            </IonCol>
            <IonCol size="12" sizeXl="3">
              <IonToolbar className="custom_toolbar">
                {/* <IonSegment color="success" value={Segment} onIonChange={(e) => onSegmentChanged(e.detail.value)}> */}
                <IonSegment color="success" value={"detail"}>
                  <IonSegmentButton value="detail" className="attendee-segment-button">
                    <IonLabel>Detail</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="schedule" className="attendee-segment-button">
                    <IonLabel>Schedule</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="attendees" className="attendee-segment-button">
                    <IonLabel>Attendees</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="companies" className="attendee-segment-button">
                    <IonLabel>Companies</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonToolbar>
            </IonCol>
            <IonCol size="10">
              {/* <IonImg src="/assets/reg-confirmed.svg" /> */}
              <IonImg src={imageUrl} />
            </IonCol>
            <IonCol size="10">
              <h1 style={{ fontWeight: 700, fontSize: "32px" }}>{EventData.Name}</h1>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol offset="1" sizeXs="11" sizeMd="2">
              <IonCard class="detail-card">
                <IonCardContent>
                  <IonItem lines="none">
                    <IonThumbnail slot="start">
                      <IonImg src="/assets/icon/cisco.svg" />
                    </IonThumbnail>
                    <ul className="detail-list">
                      <li className="first">CISCO</li>
                      <li className="second">Gold Sponser</li>
                    </ul>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol sizeXs="11" sizeMd="2">
              <IonCard class="detail-card">
                <IonCardContent>
                  <IonItem lines="none">
                    <IonThumbnail slot="start">
                      <IonImg src="/assets/icon/growthcloud.svg" />
                    </IonThumbnail>
                    <ul className="detail-list">
                      <li className="first">GrowthCloud</li>
                      <li className="second"> Presenting Sponser</li>
                    </ul>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol sizeXs="11" sizeMd="2">
              <IonCard class="detail-card">
                <IonCardContent>
                  <IonItem lines="none">
                    <IonThumbnail slot="start">
                      <IonImg src="/assets/icon/moonpay.svg" />
                    </IonThumbnail>
                    <ul className="detail-list">
                      <li className="first">MoonPay</li>
                      <li className="second"> Silver Sponser</li>
                    </ul>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow class="ion-padding">
            <IonCol offset="1" size="10" sizeMd="12" class="ion-padding-top">
              <IonLabel class="event-calander" style={{ fontWeight: "bold" }}>
                <IonIcon slot="start" icon={calendarClearOutline} class="detail-icon"></IonIcon>{" "}
                &nbsp;
                {StartDate}
              </IonLabel>
              <IonLabel style={{ display: "block" }}>
                <a href="#" style={{ marginLeft: "26px" }} className="event-link">
                  Add to calendar
                </a>
              </IonLabel>
            </IonCol>
            <IonCol offset="1" size="10" sizeMd="12" class="ion-padding-top">
              <IonLabel style={{ fontSize: "18px" }}>
                <IonIcon slot="start" icon={stopwatchOutline} class="detail-icon"></IonIcon>
                &nbsp;
                {StartTime} - {EndTime}
              </IonLabel>
            </IonCol>
            <IonCol offset="1" size="10" sizeMd="12" class="ion-padding-top">
              <IonLabel class="event-calander" style={{ fontWeight: "bold" }}>
                <IonIcon slot="start" icon={locationOutline} class="detail-icon"></IonIcon>
                &nbsp;
                <b> {EventData.Location ? EventData.Location.PlainText : ""} </b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow class="ion-padding">
            <IonCol offset="1" size="11" sizeXl="6" class="ion-padding-bottom">
              <IonTitle class="ion-no-padding" style={{ fontWeight: "bold" }}>
                About
              </IonTitle>
              <IonText>{EventData.Description ? EventData.Description.PlainText : ""}</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="ion-padding">
            <IonCol offset="1" size="11" sizeXl="6">
              <IonTitle class="ion-no-padding" style={{ fontWeight: "bold" }}>
                Location
              </IonTitle>
              <IonImg src="/assets/basemap.png" />
            </IonCol>
            <IonCol offset="1" sizeMd="12" class="ion-padding-top" style={{ fontSize: "13px" }}>
              <IonLabel>
                <b> {EventData.Location ? EventData.Location.PlainText : ""} </b>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Details;
