import { Preferences } from "@capacitor/preferences";
import ProfileCard from "../../components/ProfileCard";
import { useEffect, useState } from "react";
import Http from "../../services/Http.Service";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

const CompanyList = () => {
  const [Event, setEvent] = useState<any>({});
  const [ProfileImage, setProfileImage] = useState<any>([]);

  let ProfileImageUrl = "";
  const getData = async () => {
    let event = await Preferences.get({ key: "event" });
    if (event.value != null) {
      setEvent(JSON.parse(event.value));
    }
    // await getImage()
  };

  // const getImage = () => {
  //     let ProfileImages: any = []
  //     console.log(Event, "Event");

  //     try {
  //         Event && Event.Attendees.length > 0 &&
  //             Event.Attendees.map((attendee: any) => {
  //                 Http.getFileURL(attendee.ProfileImage.File).then((data) => {
  //                     if (data && data.data && data.data.data && data.data.data.url) {
  //                         ProfileImageUrl = data.data.data.url;
  //                         ProfileImages.push({ imageUrl: ProfileImageUrl, attendeeId: attendee.id })

  //                         // setProfileImage([...ProfileImage , { imageUrl: ProfileImageUrl, attendeeId: attendee.id }])
  //                     }
  //                 })
  //             })
  //     }
  //     catch (error) {
  //         console.log(error)
  //     }
  //     console.log("++++", ProfileImages);
  //     setProfileImage(ProfileImages)
  // }

  console.log(Event.Company);
  // console.log(ProfileImage);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeLg="3" className="card_alignment">
              <ProfileCard
                Name="Cisco"
                role="CyberSecurity"
                imgUrl="/assets/icon/Elipse.png"
                openProfile={() => alert("")}
              />
            </IonCol>
            <IonCol size="12" sizeLg="3" className="card_alignment">
              <ProfileCard
                Name="Amazon"
                role="IT"
                imgUrl="/assets/icon/Elipse.png"
                openProfile={() => alert("")}
              />
            </IonCol>
            {/* {
                            Event.Attendees ?
                                Event.Attendees.map((attendee: any, index: number) => {
                                    return (
                                        <IonCol size='12' sizeLg='3' className='card_alignment'>
                                            <ProfileCard key={attendee.id} Name={attendee.FirstName + ' ' + attendee.LastName} role={attendee.JobTitle} imgUrl='/assets/icon/Elipse.png' />
                                        </IonCol>
                                    )
                                })
                                : <></>
                        } */}
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default CompanyList;
