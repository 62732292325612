export const UserSchema = {
  FirstName: "",
  LastName: "",
  Email: "",
  Mobile: "",
  Company: "",
  Jobtitle: "",
  Password: "",
  EventPassportCode: "",
};
