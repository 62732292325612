import "./ImpactEventRegistered.css";

const ImpactEventRegistered = () => {
  return (
    <div className="impact-container">
      <div className="page impact-landing">
        <div className="cisco-aws-navbar">
          <img className="aws-img" src="/assets/aws_logo.png" alt="AWS" />
          <img className="cisco-img" src="/assets/CiscoWhiteLogo.png" alt="Cisco" />
        </div>
        <div className="impact-landing-group">
          <div className="landing-title" style={{ marginTop: "80px" }}>
            <div className="title-lines-container">
              <div className="title-line"></div>
              <span className="landing-title-p">Elevate</span>
              <div className="title-line"></div>
            </div>
            <span className="landing-title-p">Your Sales</span>
          </div>
          <div className="landing-center-container" style={{ marginTop: "80px" }}>
            <span className="center-title">Registration Pending</span>
            <span className="center-text">
              Thank you for your submission. You have now been added to the event waitlist. Due to
              high demand, once your seat is confirmed, you will receive an email confirmation with
              your registration details.
            </span>
          </div>
        </div>
        <div className="cisco-aws-footer">
          {/* <img className="aws-img" src="/assets/aws_logo.png" alt="AWS" /> */}
          {/* <img className="cisco-img" src="/assets/CiscoWhiteLogo.png" alt="Cisco" /> */}
        </div>
      </div>
    </div>
  );
};

export default ImpactEventRegistered;
