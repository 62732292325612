import { IonContent, IonPage } from "@ionic/react";
import "./ImpactEvent.css";
import ImpactLanding from "./ImpactLanding";
import ImpactRegistration from "./ImpactRegistration";
import { createRef } from "react";

const ImpactEvent = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="impact-container">
          <ImpactLanding />
          <ImpactRegistration />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ImpactEvent;
