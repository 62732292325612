// import { useCallback, useEffect, useState } from 'react';
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
// import "./Maps.css"

// const containerStyle = {
//   width: '800px',
//   height: '800px'
// };

// const Map = () => {
//   const [center, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 });
//   useEffect(() => {
//     setMapCenter({ lat: 37.324247, lng: -121.880093 })
//   }, [])
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyDzgwHVEF-mQi7A213FOObdUiOBaickik4"
//   })

//   const [map, setMap] = useState(null)

//   const onLoad = useCallback(function callback(map:any) {
//     // This is just an example of getting and using the map instance!!! don't just blindly copy!
//     const bounds = new window.google.maps.LatLngBounds(center);
//     map.fitBounds(bounds);

//     setMap(map)
//   }, [])
//   const onUnmount = useCallback(function callback(map:any) {
//     setMap(null)
//   }, [])

//   return isLoaded ? (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={center}
//       zoom={6}
//       onLoad={onLoad}
//       onUnmount={onUnmount}
//     >
//       { /* Child components, such as markers, info windows, etc. */ }
//       <></>
//     </GoogleMap>
// ) : <></>
// };

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import "./Maps.css";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const Map = (props: any) => {
  const [center, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 });
  useEffect(() => {
    if (props.lat && props.long) {
      setMapCenter({ lat: props.lat, lng: props.long });
    }
  }, [props]);
  const onLoad = (marker: any) => {
    console.log("marker: ", marker);
  };
  return (
    <LoadScript googleMapsApiKey="AIzaSyDzgwHVEF-mQi7A213FOObdUiOBaickik4">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        {/* Child components, such as markers, info windows, etc. */}
        <Marker onLoad={onLoad} position={center} />
        <></>
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
