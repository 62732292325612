import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import "./RegistrationConfirmed.css";
import { Preferences } from "@capacitor/preferences";

const RegistrationConfirmed = () => {
  //  state set for the event and the contact values
  const [EventData, setEventData] = useState<any>({});
  const [userData, setUserData] = useState<any>({});
  const [imageUrl, setImageUrl] = useState<any>({});

  // get the all the data ..
  const eventData = async () => {
    let event = await Preferences.get({ key: "event" });
    let user = await Preferences.get({ key: "UserData" });
    let imageUrl = await Preferences.get({ key: "imageUrl" });

    // console.log(event.value)

    //  condition for the value is not null
    if (event.value != null) {
      setEventData(JSON.parse(event.value));
    }
    if (user.value != null) {
      let Data = JSON.parse(user.value);
      setUserData(Data.data.data.data);
    }
    if (imageUrl.value != null) {
      setImageUrl(imageUrl.value);
    }
  };

  // const str = EventData.Starts;
  const startdate = new Date(EventData.Starts).toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
  // const enddate = new Date(EventData.Ends).toLocaleDateString('en-us', { weekday:"long", month:"long", day:"numeric"});
  const starttime = new Date(EventData.Starts).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });
  const endtime = new Date(EventData.Ends).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });
  // console.log(starttime, '------', endtime);

  useEffect(() => {
    eventData();
  }, []);

  // console.log(EventData)

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ paddingTop: "15px" }}>
          <IonRow style={{ justifyContent: "center" }}>
            <IonImg style={{ maxWidth: "310px" }} src="/assets/icon/Logo.svg" />
          </IonRow>
          <IonRow
            style={{
              textAlign: "center",
              paddingTop: "45px",
              justifyContent: "center",
            }}
          >
            <IonCol size="12">
              <h1 style={{ fontWeight: 700 }}>Registration Confirmed!</h1>
            </IonCol>
            <IonCol size="12">
              <IonText>
                Congratulations! You have successfully registered for our upcoming event. We're
                excited to have you join us!
              </IonText>
            </IonCol>
            <IonCol size="6">
              {/* <IonImg src="/assets/reg-confirmed.svg" /> */}
              <IonImg src={imageUrl} />
            </IonCol>
          </IonRow>
          <IonRow style={{ textAlign: "center", justifyContent: "center" }}>
            <IonCol size="12">
              <h2 style={{ fontWeight: "bolder" }}>{EventData.Name}</h2>
            </IonCol>
            <IonCol size="12">
              <b>{startdate}</b>
              <span>
                {" "}
                -{starttime} -{endtime}
              </span>
            </IonCol>
            <IonCol size="12">
              <b> {EventData.Location ? EventData.Location.PlainText : ""} </b>
              {/* <b dangerouslySetInnerHTML={{ __html: EventData.Location }} /> */}
            </IonCol>
            <IonCol size="4">
              <IonButton fill="outline" class="add-to-calander">
                Add to Calender
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow style={{ justifyContent: "center", marginTop: "25px" }}>
            <IonCol size="6">
              <IonText>
                Please present this QR code at the registration desk on the day of the event. Have
                it ready to be scanned on your mobile device or print it out and bring a physical
                copy with you.
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow style={{ justifyContent: "center" }}>
            <IonImg src="/assets/registration/qr-code.png" />
          </IonRow>
          <IonRow style={{ justifyContent: "center", marginTop: "25px" }}>
            <IonCol size="6">
              <IonText>
                To see the event details or your registration information, log in with your email
                and enter this confirmation code: {userData.EventPassportCode}
              </IonText>
            </IonCol>
            <IonCol size="12" style={{ textAlign: "center" }}>
              <IonButton href="/attendeeinfo" class="go-to-event">
                Go to Event Details
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow
            style={{
              textAlign: "center",
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            <IonLabel>Already a GrowthEvents user?</IonLabel>
            <IonCol size="12">
              <IonButton href="/eventlogin" class="registered-event">
                See Registered Events
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default RegistrationConfirmed;
