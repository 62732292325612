interface Props {
  scrollToBottom: () => void;
}

const AnaheimLanding = ({ scrollToBottom }: Props) => {
  return (
    <div className="ana-landing">
      <div className="ana-outer-stone">
        <div className="ana-inner-stone">
          <img src="/assets/anaheim-flower.svg" className="ana-flower" alt="Flower" />
          <h2 className="ana-landing-header">Unleash Security Excellence with AWS & Cisco</h2>
          <div className="ana-landing-desc">Actionable Insights for Today's Demands</div>
          <div className="ana-date">June 13th, 2023</div>
          <div className="ana-info">
            5:30pm - 8pm
            <br />
            Poppy & Seed Anaheim, CA
          </div>
          <div style={{ marginTop: 5 }}>350 S Anaheim Blvd, Anaheim, CA 92805</div>
          <div className="ana-landing-button" onClick={scrollToBottom}>
            RSVP
            <div style={{ marginTop: -20 }}>
              <div className="chevron bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaheimLanding;
