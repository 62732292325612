import { Preferences } from "@capacitor/preferences";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonImg,
  IonLabel,
} from "@ionic/react";
import {
  arrowBackOutline,
  calendarClearOutline,
  locationOutline,
  stopwatchOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const ScheduleDetail = () => {
  const history = useHistory();
  const [Event, setEvent] = useState<any>({});

  const getData = async () => {
    let event = await Preferences.get({ key: "event" });

    if (event.value != null) {
      setEvent(JSON.parse(event.value));
    }
  };
  const StartDate = new Date(Event.Starts).toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
  const StartTime = new Date(Event.Starts).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });
  const EndTime = new Date(Event.Ends).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });

  useEffect(() => {
    getData();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol style={{ display: "flex" }}>
              <IonButton fill="clear" onClick={() => history.push("/event/info")}>
                <IonIcon icon={arrowBackOutline} style={{ marginTop: "5px" }} />
              </IonButton>
              <h5
                style={{
                  textTransform: "capitalize",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {StartDate}
              </h5>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <h5
                style={{
                  textTransform: "capitalize",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {StartDate}
              </h5>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="10" sizeMd="12">
              <IonLabel class="event-calander" style={{ fontWeight: "bold" }}>
                <IonIcon slot="start" icon={calendarClearOutline} class="detail-icon"></IonIcon>{" "}
                &nbsp;
                {StartDate}
              </IonLabel>
            </IonCol>
            <IonCol size="10" sizeMd="12">
              <IonLabel style={{ fontSize: "18px" }}>
                <IonIcon slot="start" icon={stopwatchOutline} class="detail-icon"></IonIcon>
                &nbsp;
                {StartTime} - {EndTime}
              </IonLabel>
            </IonCol>
            <IonCol size="10" sizeMd="12" class="ion-padding-top">
              <IonLabel class="event-calander">
                <IonIcon slot="start" icon={locationOutline} class="detail-icon"></IonIcon>
                &nbsp;
                {Event.Location ? Event.Location.PlainText : ""}
              </IonLabel>
            </IonCol>
            <IonCol size="10" sizeMd="12">
              <IonImg style={{ width: "50%" }} src="/assets/basemap.png" />
            </IonCol>
            <IonCol size="10" sizeMd="12" class="ion-padding-top">
              <IonLabel class="event-calander">
                <b>{Event.Location ? Event.Location.PlainText : ""}</b>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ScheduleDetail;
