import { IonGrid, IonRow, IonCol, IonIcon, IonToggle, IonItem, IonLabel } from "@ionic/react";
import { notifications } from "ionicons/icons";
import "./PrivacySetting.css";

const PrivacySetting = () => {
  return (
    <IonGrid style={{ paddingLeft: "100px" }}>
      <IonRow>
        <IonCol sizeMd="5" sizeSm="12">
          <h1
            style={{
              textTransform: "capitalize",
              color: "black",
              fontWeight: "bold",
            }}
          >
            Privacy Settings
          </h1>
          <p style={{ fontSize: "20px" }}>
            Please review and adjust the following settings to ensure that your privacy preferences
            are set to your liking.
          </p>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="4" class="ion-padding-top">
          <IonLabel class="privacySetting-label">
            <IonIcon slot="start" icon={notifications} class="detail-icon"></IonIcon>
            &nbsp; Notifications
          </IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd="4" sizeSm="12">
          <IonItem lines="none">
            Notification sound <IonToggle class="privacy-notification-icon"></IonToggle>
          </IonItem>
          <IonItem lines="none">
            Push notifications <IonToggle class="privacy-notification-icon"></IonToggle>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd="4" sizeSm="12" class="ion-padding-top">
          <IonLabel class="privacySetting-label">
            <IonIcon slot="start" icon={notifications} class="detail-icon"></IonIcon>
            &nbsp; Passport information
          </IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd="4" sizeSm="12">
          <IonItem lines="none">
            Show company <IonToggle class="privacy-notification-icon"></IonToggle>
          </IonItem>
          <IonItem lines="none">
            Show phone number <IonToggle class="privacy-notification-icon"></IonToggle>
          </IonItem>
          <IonItem lines="none">
            Show email <IonToggle class="privacy-notification-icon"></IonToggle>
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default PrivacySetting;
