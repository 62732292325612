import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { person, mail, businessOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { UserSchema } from "../../services/Types";
import { Preferences } from "@capacitor/preferences";
import "./EditProfile.css";

const EditProfile = () => {
  const [User, setUser] = useState<any>(UserSchema);
  const { FirstName, LastName, Email, Mobile, Company, Jobtitle } = User;

  const getUserData = async () => {
    let user = await Preferences.get({ key: "UserData" });
    if (user.value != null) {
      let data = JSON.parse(user.value);
      console.log(data.data.data.data);
      setUser({
        FirstName: data.data.data.data.FirstName,
        LastName: data.data.data.data.LastName,
        Email: data.data.data.data.Email,
        Mobile: data.data.data.data.Mobile,
        Company: data.data.data.data.Company,
        Jobtitle: data.data.data.data.Jobtitle,
      });
    }
  };

  const onValueChange = (e: any) => {
    console.log(e.target.value);
    setUser({ ...User, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <IonGrid style={{ paddingLeft: "100px" }}>
      <IonRow>
        <IonCol sizeMd="5" sizeSm="12">
          <h1
            style={{
              textTransform: "capitalize",
              color: "black",
              fontWeight: "bold",
            }}
          >
            Edit Profile
          </h1>
          <p style={{ fontSize: "20px" }}>
            Update your profile information as you want it to be shown on your personal QR when
            someone scans you.{" "}
          </p>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <div className="circle">
            <IonImg className="profile-pic" src="assets/icon/Elipse.png" />
          </div>
          <div className="p-image">
            <IonImg className="profile-pic" src="assets/camera.svg" />
            <input className="file-upload" type="file" accept="image/*" />
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd="4" sizeSm="12">
          <div className="">
            <IonLabel class="editprofile-label">First Name</IonLabel>
            <IonInput
              className="edit-profile-feild"
              placeholder="Jane"
              type="text"
              onIonChange={(e) => onValueChange(e)}
              value={FirstName}
              name="FirstName"
            />

            <IonLabel class="editprofile-label">Last Name</IonLabel>
            <IonInput
              className="edit-profile-feild"
              placeholder="Deo"
              type="text"
              onIonChange={(e) => onValueChange(e)}
              value={LastName}
              name="LastName"
            />

            <IonLabel class="editprofile-label">Job Title</IonLabel>
            <IonInput
              className="edit-profile-feild"
              placeholder="IT Recruiter"
              type="text"
              onIonChange={(e) => onValueChange(e)}
              value={Jobtitle}
              name="Jobtitle"
            />

            <IonLabel class="editprofile-label">Company</IonLabel>
            <IonInput
              className="edit-profile-feild"
              placeholder="Emerge"
              type="text"
              onIonChange={(e) => onValueChange(e)}
              value={Company}
              name="Company"
            />

            <IonLabel class="editprofile-label">Email</IonLabel>
            <IonInput
              className="edit-profile-feild"
              placeholder="janedoe@gmail.com"
              type="text"
              onIonChange={(e) => onValueChange(e)}
              value={Email}
              name="Email"
            />

            <IonLabel class="editprofile-label">Phone</IonLabel>
            <IonInput
              className="edit-profile-feild"
              placeholder="(585) 555-0102"
              type="text"
              onIonChange={(e) => onValueChange(e)}
              value={Mobile}
              name="Mobile"
            />

            <IonItem lines="none" style={{ padding: "0px" }}>
              <IonButton className="edit-profile-button">Save</IonButton>
            </IonItem>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default EditProfile;
