import {
  IonContent,
  IonHeader,
  IonCheckbox,
  IonPage,
  IonTitle,
  IonToolbar,
  IonImg,
  IonInput,
  IonButton,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import React, { useState } from "react";
import ExploreContainer from "../../components/ExploreContainer";
import "./Registration.css";
import "../../theme/theme.css";
import { person } from "ionicons/icons";
import { mail } from "ionicons/icons";
import { call } from "ionicons/icons";
import { business } from "ionicons/icons";
import { card } from "ionicons/icons";
import { lockClosed } from "ionicons/icons";
import { UserSchema } from "../../services/Types";
import { Contacts } from "../../services/Api.Common";
import { MakeId } from "../../services/EventPassportCode";
import Http from "../../services/Http.Service";
import { useHistory } from "react-router";

type UserType = {
  FirstName: string;
  LastName: string;
  Email: string;
  Mobile: {};
  Company: string;
  Jobtitle: string;
  Password: string;
  EventPassportCode: string;
};

async function ContactRegistration(User: UserType, code: string) {
  const Req = {
    FirstName: User.FirstName,
    LastName: User.LastName,
    Email: [
      {
        Email: User.Email,
        Primary: true,
      },
    ],
    Mobile: User.Mobile,
    Company: User.Company,
    JobTitle: User.Jobtitle,
    Password: User.Password,
    EventPassportCode: code,
  };

  try {
    let Res = await Http.CreateUpdate(Contacts, Req);
    console.log(Res.data);
    return Res.data;
  } catch (error) {
    console.log(error);
  }
}

const Registration: React.FC = () => {
  const history = useHistory();
  let PassCode = MakeId(10);

  const [User, setUser] = useState(UserSchema);
  const { FirstName, LastName, Email, Mobile, Company, Jobtitle, Password } = User;

  const onValueChange = (e: any) => {
    console.log(e.target.value);
    setUser({ ...User, [e.target.name]: e.target.value });
  };

  const Registration = async () => {
    console.log(PassCode, "Regitration vala");

    // let event = JSON.stringify(Data)
    let res = await ContactRegistration(User, MakeId(10));
    console.log(res);
    if (!res.error) {
      history.push("/events");
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ padding: "0px", height: "100vh" }}>
          <IonRow>
            <IonCol size="12" size-lg="6" style={{ padding: "0px" }} className="login_img"></IonCol>
            <IonCol size="12" size-lg="6" className="Registration_content_box">
              <div style={{ width: "100%" }}>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <IonImg
                      className="custom_logo1"
                      src="assets/home_screen_images/Logo.svg"
                      alt="Logo"
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <IonLabel style={{ fontSize: "14px", marginTop: "5px" }}>
                      Your event passport
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <IonLabel
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        marginTop: "20px",
                      }}
                    >
                      Create an account
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <div className="login_form">
                      <IonItem lines="none" style={{ position: "relative" }}>
                        <IonIcon icon={person} className="custom_icons" />
                        <IonInput
                          onIonChange={(e) => onValueChange(e)}
                          value={FirstName}
                          name="FirstName"
                          className="custom_input_filed register_input_filed"
                          placeholder="Name*"
                          type="text"
                        />
                      </IonItem>
                      <IonItem lines="none" style={{ position: "relative" }}>
                        <IonIcon icon={person} className="custom_icons" />
                        <IonInput
                          onIonChange={(e) => onValueChange(e)}
                          value={LastName}
                          name="LastName"
                          className="custom_input_filed register_input_filed"
                          placeholder="Last Name*"
                          type="text"
                        />
                      </IonItem>
                      <IonItem lines="none" style={{ position: "relative" }}>
                        <IonIcon icon={mail} className="custom_icons" />
                        <IonInput
                          onIonChange={(e) => onValueChange(e)}
                          value={Email}
                          name="Email"
                          className="custom_input_filed register_input_filed"
                          placeholder="Email*"
                          type="email"
                        />
                      </IonItem>
                      <IonItem lines="none" style={{ position: "relative" }}>
                        <IonIcon icon={call} className="custom_icons" />
                        <IonInput
                          onIonChange={(e) => onValueChange(e)}
                          value={Mobile}
                          name="Mobile"
                          className="custom_input_filed register_input_filed"
                          placeholder="Phone Number*"
                          type="text"
                        />
                      </IonItem>
                      <IonItem lines="none" style={{ position: "relative" }}>
                        <IonIcon icon={business} className="custom_icons" />
                        <IonInput
                          onIonChange={(e) => onValueChange(e)}
                          value={Company}
                          name="Company"
                          className="custom_input_filed register_input_filed"
                          placeholder="Company(optional)*"
                          type="text"
                        />
                      </IonItem>
                      <IonItem lines="none" style={{ position: "relative" }}>
                        <IonIcon icon={card} className="custom_icons" />
                        <IonInput
                          onIonChange={(e) => onValueChange(e)}
                          value={Jobtitle}
                          name="Jobtitle"
                          className="custom_input_filed register_input_filed"
                          placeholder="Job Title(optional)*"
                          type="text"
                        />
                      </IonItem>
                      <IonItem lines="none" style={{ position: "relative" }}>
                        <IonIcon icon={lockClosed} className="custom_icons" />
                        <IonInput
                          onIonChange={(e) => onValueChange(e)}
                          value={Password}
                          name="Password"
                          className="custom_input_filed register_input_filed"
                          placeholder="Create your password*"
                          type="password"
                        />
                      </IonItem>

                      <div style={{ padding: "0 16px" }}>
                        <IonButton
                          onClick={() => Registration()}
                          className="secondary_btn registration_btn "
                        >
                          Register
                        </IonButton>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex", marginTop: "20px" }}
                  >
                    <div
                      style={{
                        color: "#a3a3a3",
                        display: "flex",
                        overflow: "hidden",
                      }}
                    >
                      _______________{" "}
                      <span style={{ color: "#000", fontWeight: "bold" }}> or </span>
                      _______________
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <div className="logo-container">
                      <IonImg src="assets/icon/socials/Amazon.png" alt="Amazon" />
                      <IonImg src="assets/icon/socials/Google.png" alt="Google" />
                      <IonImg src="assets/icon/socials/Microsoft.png" alt="Microsoft" />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center already_register"
                    style={{ display: "flex", marginTop: "20px" }}
                  >
                    <IonLabel style={{ fontWeight: "bold" }}>
                      New to GrowthEvent? <a href="/login">Login</a>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="12"
                    size-sm="12"
                    class="ion-justify-content-center"
                    style={{ textAlign: "center", marginTop: "25px" }}
                  >
                    <IonLabel>
                      By logging in you accept the{" "}
                      <span style={{ fontWeight: "bold" }}>Terms and Conditions</span> and you agree
                      with our <span style={{ fontWeight: "bold" }}>Privacy Policy</span>
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Registration;
