import axios from "axios";
import { HostApi, Token } from "./Api.Common";

export default axios.create({
  baseURL: `${HostApi}`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Token}`,
  },
});
