import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

const Terms = () => {
  return (
    <IonContent>
      <IonGrid>
        <IonRow
          style={{
            background: "#26B058",
            color: "white",
            height: "300px",
            alignItems: "center",
          }}
        >
          <IonCol>
            <IonRow>
              <IonCol>
                <h1 style={{ fontWeight: "bold" }}>growthevents.app</h1>
                <h1 style={{ fontWeight: "bold" }}>Terms and Conditions</h1>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <p>
              These Terms govern <br />
              <ul style={{ marginBottom: "0px", marginTop: "0px" }}>
                <li>the use of this Application, and,</li>
                <li>any other related Agreement or legal relationship with the Owner</li>
              </ul>
              <p style={{ marginTop: "2px" }}>
                in a legally binding way. Capitalized words are defined in the relevant dedicated
                section of this document.
              </p>
              The User must read this document carefullyz <br />
              This Application is provided by: <br />
              GrowthCloud412 Linden AvenueRochester, NY 14625 <br />
              Owner contact email: info@growthcloud.com <br />
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h2 style={{ fontWeight: "bold" }}>Terms of Use</h2>
            <p style={{ marginBottom: "0px" }}>
              Unless otherwise specified, the terms of use detailed in this section apply generally
              when using this Application. <br />
              Single or additional conditions of use or access may apply in specific scenarios and
              in such cases are <br />
              additionally indicated within this page. <br />
              <br />
              By using this Application, Users confirm to meet the following requirements:
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5 style={{ fontWeight: "bold" }}>Content on this Application</h5>
            <p style={{ marginBottom: "0px" }}>
              Unless where otherwise specified or clearly recognizable, all content available on
              this Application is owned or <br />
              provided by the Owner or its licensors.
              <br />
              <br />
              The Owner undertakes its utmost effort to ensure that the content provided on this
              Application infringes no <br />
              applicable legal provisions or third-party rights. However, it may not always be
              possible to achieve such a <br />
              result.In such cases, without prejudice to any legal prerogatives of Users to enforce
              their rights, Users are kindly <br />
              asked to preferably report related complaints using the contact details provided in
              this document.
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5 style={{ fontWeight: "bold" }}>Access to external resources</h5>
            <p style={{ marginBottom: "0px" }}>
              Through this Application Users may have access to external resources provided by third
              parties. Users <br />
              acknowledge and accept that the Owner has no control over such resources and is
              therefore not responsible <br />
              for their content and availability. <br />
              <br />
              Conditions applicable to any resources provided by third parties, including those
              applicable to any possible <br />
              grant of rights in content, result from each such third parties’ terms and conditions
              or, in the absence of those, <br />
              applicable statutory law.
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5 style={{ fontWeight: "bold" }}>Acceptable use</h5>
            <p>
              This Application and the Service may only be used within the scope of what they are
              provided for, under these <br />
              Terms and applicable law. <br />
              Users are solely responsible for making sure that their use of this Application and/or
              the Service violates no <br />
              applicable law, regulations or third-party rights.
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="7">
            <IonItem lines="none" className="custom_select">
              <IonLabel>Common Provisions</IonLabel>
              <IonSelect interface="popover">
                <IonSelectOption value="apples">Apples</IonSelectOption>
                <IonSelectOption value="oranges">Oranges</IonSelectOption>
                <IonSelectOption value="bananas">Bananas</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="7">
            <IonItem lines="none" className="custom_select">
              <IonLabel>Definitions and legal references</IonLabel>
              <IonSelect interface="popover">
                <IonSelectOption value="apples">Apples</IonSelectOption>
                <IonSelectOption value="oranges">Oranges</IonSelectOption>
                <IonSelectOption value="bananas">Bananas</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Terms;
