const ComingSoon = () => {
  return (
    <div
      style={{
        textAlign: "center",
        backgroundColor: " #232f3e ",
      }}
    ></div>
  );
};

export default ComingSoon;
