const ImpactLanding = () => {
  const handleScroll = () => {
    const divElement = document.getElementById("registration");
    divElement && divElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="page impact-landing">
      <div className="cisco-aws-navbar">
        <img className="aws-img" src="/assets/aws_logo.png" alt="AWS" />
        <img className="cisco-img" src="/assets/CiscoWhiteLogo.png" alt="Cisco" />
      </div>
      <div className="impact-landing-group">
        <div className="landing-title">
          <div className="title-lines-container">
            <div className="title-line"></div>
            <span className="landing-title-p">Elevate</span>
            <div className="title-line"></div>
          </div>
          <span className="landing-title-p">Your Sales</span>
        </div>
        <div className="landing-center-container">
          <span className="center-title">Get On Cloud</span>
          <span className="center-text">with AWS & Cisco for a</span>
          <span className="center-text-vip">VIP Happy Hour</span>

          <div className="date-container">
            <span className="date">Sunday, August 27, 2023</span>
          </div>
          <p className="center-text">7pm - 9pm</p>
          <p className="center-text">Mandalay Bay</p>
          <p className="center-text">Hazel Lounge Las Vegas</p>
          <p className="center-text">3950 S Las Vegas Blvd Las Vegas, NV 89119</p>
        </div>
        <div className="landing-button" onClick={handleScroll}>
          RSVP
          <div style={{ marginTop: -20 }}>
            <div className="chevron bottom landing-chevron-button"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactLanding;
