import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from "@ionic/react";
import "./AttendeeInfo.css";
import { useEffect, useState } from "react";
import {
  time,
  location,
  bookmarkOutline,
  shareSocial,
  mail,
  person,
  businessOutline,
} from "ionicons/icons";
import EventDetail from "./EventDetail";
import { UserSchema } from "../../services/Types";
import { Preferences } from "@capacitor/preferences";
import Http from "../../services/Http.Service";
import { Contacts } from "../../services/Api.Common";

// type UserType = {
//     FirstName: string;
//     LastName: string;
//     Email: string;
//     Mobile: {};
//     Company: string;
//     Jobtitle: string;
//     Password: string;
//     EventPassportCode: string;
// };
// async function ContactUpdate(User: UserType, Id: string) {
//     const Req = {
//         payload: {
//             FirstName: User.FirstName,
//             LastName: User.LastName,
//             Mobile: User.Mobile,
//             Company: User.Company,
//             JobTitle: User.Jobtitle,

//         }
//     }

//     try {
//         // let Res = await Http.CreateUpdate(Contacts, Req);
//         const dataa = await Http.Update("/tenant/crm/records/Contacts/" + Id, Req)
//         console.log(dataa.data);
//         return dataa.data;

//     } catch (error) {
//         console.log(error)
//     }
// }

const AttendeeInfo = () => {
  const [Segment, setSegment] = useState("detail");
  const [Detail, setDetail] = useState(true);
  const [Schedule, setSchedule] = useState(false);
  const [Information, setInfo] = useState(false);
  const [User, setUser] = useState<any>(UserSchema);
  const [Event, setEvent] = useState<any>({});
  const [ImageUrl, setImageUrl] = useState<any>({});
  const [Id, setId] = useState("");
  // const { FirstName, LastName, Email, Mobile, Company, Jobtitle } = User

  let sch_StartTime = "";
  let sch_EndTime = "";

  const eventData = async () => {
    // let user = await Preferences.get({ key: "UserData" })
    let event = await Preferences.get({ key: "event" });
    let imageUrl = await Preferences.get({ key: "imageUrl" });

    // console.log(user.value)

    //  condition for the value is not null
    if (event.value != null) {
      setEvent(JSON.parse(event.value));
    }
    if (imageUrl.value != null) {
      setImageUrl(imageUrl.value);
    }
    // if (user.value != null) {
    //     let data = JSON.parse(user.value)
    //     console.log(data.data.data.data)
    //     setId(data.data.data.data.id)
    //     setUser({
    //         FirstName: data.data.data.data.FirstName,
    //         LastName: data.data.data.data.LastName,
    //         Email: data.data.data.data.Email[0].Email,
    //         Mobile: data.data.data.data.Mobile,
    //         Company: data.data.data.data.Company,
    //         Jobtitle: data.data.data.data.Jobtitle,
    //     })
    // }
  };

  // const onValueChange = (e: any) => {
  //     console.log(e.target.value);
  //     setUser({ ...User, [e.target.name]: e.target.value })
  // }

  // const UpdateUserInfo = async () => {

  //     const res = await ContactUpdate(User, Id)
  //     if (res != null) {
  //         if (res.error === false) {
  //             alert("Data Updated successfully");
  //         }
  //     }
  // }

  const onSegmentChanged = (e: string | undefined) => {
    if (e === "detail") {
      setSegment(e);
      setDetail(true);
      setSchedule(false);
      setInfo(false);
    } else if (e === "schedule") {
      setSegment(e);
      setSchedule(true);
      setDetail(false);
      setInfo(false);
    }
    // else if (e === 'information') {
    //     setSegment(e)
    //     setInfo(true)
    //     setSchedule(false)
    //     setDetail(false)
    // }
  };

  // const gotoEditInfo = () => {
  //     setSegment('information')
  //     setInfo(true)
  //     setSchedule(false)
  //     setDetail(false)
  // }

  const StartDate = new Date(Event.Starts).toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  useEffect(() => {
    eventData();
  }, []);
  return (
    <IonPage>
      <IonContent>
        <IonGrid className="content_page">
          <IonRow className="custom_header_margin">
            <IonCol className="custom_logo">
              <IonImg
                className="logo1"
                src="assets/home_screen_images/growth_logo.svg"
                style={{ width: "35px" }}
              />
              <IonImg
                src="assets/home_screen_images/Logo.svg"
                className="header_logo_img_light_mode logo2"
                style={{ width: "200px" }}
              />
              <IonImg
                src="assets/home_screen_images/logodarkmode.png"
                className="header_logo_img_dark_mode"
                style={{ width: "200px", display: "none" }}
              />
            </IonCol>
            <IonCol size="9" sizeLg="3">
              <IonToolbar className="custom_toolbar">
                <IonSegment
                  color="success"
                  value={Segment}
                  onIonChange={(e) => onSegmentChanged(e.detail.value)}
                >
                  <IonSegmentButton value="detail" className="attendee-segment-button">
                    <IonLabel>Detail</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="schedule" className="attendee-segment-button">
                    <IonLabel>Schedule</IonLabel>
                  </IonSegmentButton>
                  {/* <IonSegmentButton value="information" className='attendee-segment-button'>
                                        <IonLabel>Information</IonLabel>
                                    </IonSegmentButton> */}
                </IonSegment>
              </IonToolbar>
            </IonCol>
          </IonRow>
          {Detail && <EventDetail />}
          {Schedule && (
            <>
              <IonRow>
                <IonCol>
                  <IonImg src={ImageUrl} alt="hero-image" />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <h2 className="text_style" style={{ fontSize: "32px" }}>
                    {Event.Name}
                  </h2>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <b style={{ marginBottom: "10px" }}>{StartDate}</b>
                </IonCol>
              </IonRow>
              {Event.EventSchedule.map((schedule: any, index: number) => {
                sch_StartTime = new Date(schedule.Start).toLocaleTimeString("en-us", {
                  hour: "numeric",
                  minute: "numeric",
                });
                sch_EndTime = new Date(schedule.End).toLocaleTimeString("en-us", {
                  hour: "numeric",
                  minute: "numeric",
                });
                return (
                  <IonRow key={index}>
                    <IonCol
                      col-sizeSm="12"
                      size="3"
                      sizeXs="12"
                      sizeXl="3"
                      style={{ padding: "0" }}
                    >
                      <IonCard className="schedule_card">
                        <IonCardHeader className="schedule-card-header1">
                          <IonCardSubtitle className="schedule-card-subtitle">
                            {sch_StartTime} - {sch_EndTime}
                          </IonCardSubtitle>
                          <IonCardTitle>
                            <h6 style={{ fontWeight: "bold" }} className="attendee-heading">
                              {schedule.Name}
                            </h6>
                          </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ display: "flex", color: "black" }}>
                          <div className="schedule_block">
                            <IonIcon icon={time} />
                            <IonLabel className="schedule_label">{schedule.Duration} min</IonLabel>
                          </div>
                          <div className="schedule_block">
                            <IonIcon icon={location} />
                            <IonLabel className="schedule_label">{schedule.Location}</IonLabel>
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                );
              })}
            </>
          )}
          {/* {Information &&
                        <>
                            <IonRow>
                                <IonCol>
                                    <h3 style={{ fontSize: '28px', marginBottom: '0px' }} className='text_style'>Attendee Details</h3>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <p>You can edit or review your information registration below, you will <br />
                                        be notified by email with the updated information. </p>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol>
                                    <div className='event_attendee_form att_info'>
                                        <IonItem lines='none'>
                                            <IonIcon icon={person} className="register_events_icons schedule_icons" />
                                            <IonInput
                                                onIonChange={(e) => onValueChange(e)}
                                                value={FirstName}
                                                name="FirstName"
                                                className='custom_input_filed event_landing_input att_det_input' placeholder="John" type='text' />
                                        </IonItem>
                                        <IonItem lines='none'>
                                            <IonIcon icon={person} className="register_events_icons schedule_icons" />
                                            <IonInput
                                                onIonChange={(e) => onValueChange(e)}
                                                value={LastName}
                                                name="LastName"
                                                className='custom_input_filed event_landing_input att_det_input' placeholder="Doe" type='text' />
                                        </IonItem>
                                        <IonItem lines='none'>
                                            <IonIcon icon={mail} className="register_events_icons schedule_icons" />
                                            <IonInput
                                                onIonChange={(e) => onValueChange(e)}
                                                value={Email}
                                                disabled
                                                name="Email"
                                                className='custom_input_filed event_landing_input att_det_input' placeholder="johndoe@gmail.com" type='email' />
                                        </IonItem>
                                        <IonItem lines='none'>
                                            <IonImg src='assets/icon/contact.svg' className="register_events_icons update_form_img" />
                                            <IonInput
                                                onIonChange={(e) => onValueChange(e)}
                                                value={Mobile}
                                                name="Mobile"
                                                className='custom_input_filed event_landing_input att_det_input' placeholder="(585) 555-6789" type='number' />
                                        </IonItem>
                                        <IonItem lines='none'>
                                            <IonIcon icon={businessOutline} className="register_events_icons schedule_icons" />
                                            <IonInput
                                                onIonChange={(e) => onValueChange(e)}
                                                value={Company}
                                                name="Company"
                                                className='custom_input_filed event_landing_input att_det_input' placeholder="PWC" type='text' />
                                        </IonItem>
                                        <IonItem lines='none'>
                                            <IonImg src='assets/icon/designation.svg' className="register_events_icons update_form_img" />
                                            <IonInput
                                                onIonChange={(e) => onValueChange(e)}
                                                value={Jobtitle}
                                                name="Jobtitle"
                                                className='custom_input_filed event_landing_input att_det_input' placeholder="HR Manager" type='text' />
                                        </IonItem>
                                        <IonItem lines='none' style={{ marginTop: '20px' }}>
                                            <IonButton
                                                onClick={() => UpdateUserInfo()}
                                                className='secondary_btn'>Update Information</IonButton>
                                        </IonItem>
                                    </div>
                                </IonCol>
                            </IonRow>
                        </>
                    } */}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AttendeeInfo;
