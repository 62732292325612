import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Http from "../../services/Http.Service";
import { Contacts, EventCodeSearch } from "../../services/Api.Common";
import { MakeId, QueryBySearchs, Filter } from "../../services/EventPassportCode";
import { Preferences } from "@capacitor/preferences";

async function GetData(Event: string, imageUrl: string) {
  await Preferences.clear();

  await Preferences.set({
    key: "event",
    value: Event,
  });
  await Preferences.set({
    key: "imageUrl",
    value: imageUrl,
  });

  // console.log(Event);
  console.log(imageUrl);
}

const JoinUs = () => {
  const [Data, setData] = useState<any>([]);
  const [coverImageURL, setImageUrl] = useState<any>([]);

  // url p
  const { Code }: { Code: string } = useParams();
  // console.log(Code)

  const myFilters: Filter[] = [{ FieldName: "Code", Code: Code }];

  const querys = QueryBySearchs(myFilters);

  const App = async () => {
    const event = await Http.Update(EventCodeSearch, querys);

    let eventData = event.data.data.data[0];

    await Http.getFileURL(eventData.CoverImage.File).then((data) => {
      if (data && data.data && data.data.data && data.data.data.url) {
        setImageUrl({
          coverImageURL: data.data.data.url,
        });
      }
    });
    setData(eventData);
  };
  GetData(JSON.stringify(Data), coverImageURL.coverImageURL);
  // console.log(coverImageURL.coverImageURL);

  const startdate = new Date(Data.Starts).toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
  // const enddate = new Date(Data.Ends).toLocaleDateString('en-us', { weekday:"long", month:"long", day:"numeric"});
  const starttime = new Date(Data.Starts).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });
  const endtime = new Date(Data.Ends).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });

  useEffect(() => {
    App();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ paddingTop: "15px" }}>
          <IonRow style={{ justifyContent: "center" }}>
            <IonImg style={{ maxWidth: "310px" }} src="/assets/icon/Logo.svg" />
          </IonRow>
          <IonRow
            style={{
              textAlign: "center",
              paddingTop: "45px",
              justifyContent: "center",
            }}
          >
            <IonCol size="12">
              <h1 style={{ fontWeight: 700 }}>Join Us!</h1>
            </IonCol>
            <IonCol size="12">
              <IonText>
                Congratulations! You have been invited for our upcoming event. We're excited to have
                you join us!
              </IonText>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              {/* <IonImg src="/assets/reg-confirmed.svg" /> */}
              <IonImg src={coverImageURL.coverImageURL} />
            </IonCol>
          </IonRow>
          <IonRow style={{ textAlign: "center", justifyContent: "center" }}>
            <IonCol size="12">
              <h2 style={{ fontWeight: "bolder" }}>{Data.Name}</h2>
              {/* <h2 style={{ fontWeight: 'bolder' }}>StartUp Job Fair</h2> */}
            </IonCol>
            <IonCol size="12">
              <b>{startdate}</b>
              <span>
                {" "}
                -{starttime} -{endtime}
              </span>
            </IonCol>
            <IonCol size="12">
              <b> {Data.Location ? Data.Location.PlainText : ""} </b>
              {/* <b dangerouslySetInnerHTML={{ __html: Data.Location }} /> */}
            </IonCol>
            <IonCol size="12" class="ion-padding-top">
              <IonButton href="/details" color="success">
                Go to Event Details
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default JoinUs;
