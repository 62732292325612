// Api end points <https://api.growthcloud.com>
export const HostApi = "https://api.growthcloud.com/";
export const EventCodeSearch = "tenant/crm/records/Events/search"; // searching the event
export const EventMapSearch = "tenant/crm/records/EventMap/search"; // searching by the event map
export const EventMap = "tenant/crm/records/EventMap"; //
export const EventCode = "tenant/crm/records/Events";
export const Companies = "tenant/crm/records/Companies";
export const ContactSearch = "tenant/crm/records/Contacts/search";
export const Contacts = "tenant/crm/records/Contacts";
export const EventContacts = "tenant/crm/records/EventContacts";
export const EventContactsSearch = "tenant/crm/records/EventContacts/search";
export const EventInvitesSearch = "tenant/crm/records/EventInvites/search";
export const CreateContact = "tenant/crm/records/Contacts";
export const CreateInvites = "tenant/crm/records/EventInvites";
export const EventSchedule = "tenant/crm/records/EventSchedule";
export const EventSponsors = "tenant/crm/records/EventSponsors";
export const EventScheduleSearch = "tenant/crm/records/EventSchedule/search";
export const EventAnnouncements = "tenant/crm/records/EventAnnouncements";
export const EventAnnouncementsSearch = "tenant/crm/records/EventAnnouncements/search";
// export const Token = 'gcc-0b6d8b37-5a86-424c-b8fe-60a1458b3850'// for pro
export const Token = "gcc-da62f541-0b96-422d-8cee-8cae8d7c71de"; // for dev
