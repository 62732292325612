import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import Card from "../../components/Card";
import SearchFilter from "../../components/SearchFilter";
import "../../components/SearchFilter.css";
import "./Events.css";
import EventCard from "../../components/EventCard";
import { Preferences } from "@capacitor/preferences";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

const Events: React.FC = () => {
  const [Segment, setSegment] = useState("register");
  const [Registered, setRegistered] = useState(true);
  const [Saved, setSaved] = useState(false);
  const [viewRegisteredEvents, setViewRegisteredEvents] = useState(false);
  const [viewSavedEvents, setViewSavedEvents] = useState(false);

  const [EventData, setEventData] = useState<any>({});
  const [ImageUrl, setImageUrl] = useState<any>({});

  const history = useHistory();

  const onSegmentChanged = (e: string | undefined) => {
    if (e === "register") {
      setSegment(e);
      setRegistered(true);
      setSaved(false);
      setViewRegisteredEvents(false);
      setViewSavedEvents(false);
    } else if (e === "saved") {
      setSegment(e);
      setSaved(true);
      setRegistered(false);
      setViewRegisteredEvents(false);
      setViewSavedEvents(false);
    }
  };
  const exploreRegister = () => {
    setRegistered(false);
    setSaved(false);
    setViewRegisteredEvents(true);
  };
  const exploreSave = () => {
    setRegistered(false);
    setSaved(false);
    setViewSavedEvents(true);
  };

  const getEventData = async () => {
    let event = await Preferences.get({ key: "event" });
    let imageUrl = await Preferences.get({ key: "imageUrl" });

    //  condition for the value is not null
    if (event.value != null) {
      setEventData(JSON.parse(event.value));
      exploreRegister();
    }
    if (imageUrl.value != null) {
      setImageUrl(imageUrl.value);
    }
  };

  const StartDate = new Date(EventData.Starts).toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
  const StartTime = new Date(EventData.Starts).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });

  useEffect(() => {
    getEventData();
  }, []);
  return (
    <IonGrid style={{ padding: "0px", margin: "0px" }}>
      <IonRow>
        <IonCol style={{ padding: "0px" }}>
          <Header />
        </IonCol>
      </IonRow>
      <IonRow style={{ height: "90vh" }}>
        <IonCol>
          <IonRow style={{ height: "100%" }}>
            <IonCol size="2" className="custtom_sidebar">
              <Sidebar />
            </IonCol>
            <IonCol size="12" sizeMd="10">
              {/* <IonCol size='10'> */}
              <IonRow className="custom_mobile_width">
                <IonCol style={{ padding: "20px" }}>
                  <IonHeader className="ion-no-border">
                    <IonLabel className="event-header">Events</IonLabel>
                  </IonHeader>
                </IonCol>
              </IonRow>
              <IonRow className="custom_mobile_width" style={{ marginBottom: "5px" }}>
                <IonCol>
                  <IonSegment
                    style={{ width: "200px" }}
                    color="success"
                    value={Segment}
                    onIonChange={(e) => onSegmentChanged(e.detail.value)}
                  >
                    <IonSegmentButton value="register" className="segment-btn">
                      <IonLabel>Registered</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="saved" className="segment-btn">
                      <IonLabel>Saved</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </IonCol>
              </IonRow>
              <IonRow className="custom_mobile_width">
                <IonCol>
                  {Registered && (
                    <Card
                      content="All the events you register for will appear here."
                      link="/"
                      viewEvent={() => exploreRegister()}
                    />
                  )}
                  {Saved && (
                    <Card
                      content="All the events you saved for will appear here."
                      link="/"
                      viewEvent={() => exploreSave()}
                    />
                  )}
                  {viewRegisteredEvents && (
                    <>
                      <div className="mycustom_searchfilter">
                        <SearchFilter />
                      </div>
                      <div>
                        <IonGrid>
                          <IonRow>
                            <IonCol size="12" sizeLg="3" className="card_alignment">
                              <EventCard
                                title={EventData.Name}
                                subtitle={StartDate}
                                imgUrl={ImageUrl}
                                location={EventData.Location ? EventData.Location.PlainText : ""}
                                time={StartTime}
                                description={() => history.push("/event/info")}
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    </>
                  )}
                  {viewSavedEvents && (
                    <>
                      <SearchFilter />
                      <div>
                        <IonGrid>
                          <IonRow>
                            <IonCol size="12" sizeLg="3" className="card_alignment">
                              <EventCard
                                title={EventData.Name}
                                subtitle={StartDate}
                                imgUrl={ImageUrl}
                                location={EventData.Location ? EventData.Location.PlainText : ""}
                                time={StartTime}
                                description={() => history.push("/")}
                              />
                              {/* <EventCard title='College Fest 2023' subtitle='June 1, 2023' location='Downtown Rochester' imgUrl='assets/home_screen_images/upcoming_img1.jpg' time='4:00 PM' /> */}
                            </IonCol>
                            {/* <IonCol size='12' sizeLg='3' className='card_alignment'>
                                            <EventCard title='Agile Workshop' subtitle='June 7, 2023' location='Convene' imgUrl='assets/home_screen_images/upcoming_img2.jpg' time='8:00 PM' />
                                        </IonCol>
                                        <IonCol size='12' sizeLg='3' className='card_alignment'>
                                            <EventCard title='Customer Experience Talks' subtitle='July 23, 2023' location='Online' imgUrl='assets/home_screen_images/upcoming_img3.jpg' time='8:00 PM' />
                                        </IonCol>
                                        <IonCol size='12' sizeLg='3' className='card_alignment'>
                                            <EventCard title='Tech Job Fair' subtitle='August 28, 2023' location='Tech Job Fair' imgUrl='assets/home_screen_images/upcoming_img4.jpg' time='8:00 PM' />
                                        </IonCol>
                                        <IonCol size='12' sizeLg='3' className='card_alignment'>
                                            <EventCard title='Online HR Workshop' subtitle='August 30, 2023' location='Online' imgUrl='assets/home_screen_images/upcoming_img5.jpg' time='3:00 PM' />
                                        </IonCol> */}
                          </IonRow>
                          <IonRow>
                            <IonCol size="3"></IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    </>
                  )}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Events;
